<template>
  <div v-if="entity !== null && entity.id && hasPerm('certificates.view_certificate')">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div class="field-group-block" v-if="certificateRequests.length && !isLoading(loadingName)">
      <div class="content-headline">
        <b-row>
          <b-col>
            <div class="header-bar">
              <b-row>
                <b-col>
                  <h3>Justificatifs</h3>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-for="certificateRequest of certificateRequests" :key="certificateRequest.getKey()" class="block-elt">
        <div class="header-line" :class="getStatusClass(certificateRequest)">
          <b-row>
            <b-col>
              {{ certificateRequest.certificateType.name }}
            </b-col>
            <b-col cols="3" class="text-right small-text">
              <span v-if="certificateRequest.certificateType.isPerpetual">
                Perpétuel
              </span>
              <span v-else>
                {{ certificateRequest.schoolYear.name }}
              </span>
            </b-col>
          </b-row>
          <b-row v-if="certificateRequest.certificateType.description">
            <b-col class="small-text">
              {{ certificateRequest.certificateType.description }}
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="certificateRequest.individual.id">
              {{ certificateRequest.individual.firstAndLastName() }}
            </b-col>
            <b-col v-else>Famille</b-col>
            <b-col cols="3" class="text-right small-text">
              <span v-if="certificateRequest.certificateType.isRequired">Obligatoire</span>
              <span v-else>Facultatif</span>
            </b-col>
          </b-row>
        </div>

        <div class="field-line">
          <b-row>
            <b-col cols="9">
              <div v-if="certificateRequest.certificate">
                <div v-if="certificateRequest.certificate.verifiedOn !== null">
                  <div v-if="certificateRequest.certificate.refused">
                    <i class="fa fa-times"></i> Document refusé
                    <span class="perpetual-year">{{ certificateRequest.getValidationYear() }}</span>
                  </div>
                  <div v-else-if="certificateRequest.certificate.deprecated">
                    <i class="fa fa-circle-stop"></i> Document obsolète
                    <span class="perpetual-year">{{ certificateRequest.getValidationYear() }}</span>
                  </div>
                  <div v-else>
                    <i class="fa fa-check-circle"></i> Document vérifié
                    <span class="perpetual-year">{{ certificateRequest.getValidationYear() }}</span>
                  </div>
                  <div class="small2">
                    le {{ certificateRequest.certificate.verifiedOn | dateToString('DD/MM/YYYY') }}
                    <span v-if="certificateRequest.certificate.verifiedBy" class="small2">
                      par {{ certificateRequest.certificate.verifiedBy }}
                    </span>
                  </div>
                </div>
                <div v-else>
                  <b-row>
                    <b-col>
                      <i class="fa fa-exclamation-triangle"></i> Document à vérifier
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-else>
                <div v-if="certificateRequest.certificateType.isRequired">
                  <i class="fa fa-exclamation"></i> Document à fournir
                </div>
                <div v-else>
                  <i class="fa fa-asterisk"></i> Document à fournir si concerné
                </div>
              </div>
            </b-col>
            <b-col cols="3" class="text-right">
              <div v-if="certificateRequest.certificate">
                <verify-certificate-button
                  :certificate-type="certificateRequest.certificateType"
                  :certificate="certificateRequest.certificate"
                  :individual="certificateRequest.individual"
                  :entity="entity"
                  @verified="refreshOne($event)"
                ></verify-certificate-button>
              </div>
            </b-col>
          </b-row>
          <div v-if="certificateRequest.certificate">
            <b-row v-if="certificateRequest.certificate.comments" class="comments">
              <b-col>
                <div>
                  <i class="fa fa-comment"></i> Message de la famille
                </div>
                {{ certificateRequest.certificate.comments }}
              </b-col>
            </b-row>
            <b-row v-if="certificateRequest.certificate.adminComments" class="comments">
              <b-col>
                <div>
                  <i class="fa fa-comment-alt"></i> commentaire de {{ certificateRequest.certificate.verifiedBy }}
                </div>
                {{ certificateRequest.certificate.adminComments }}
              </b-col>
            </b-row>
          </div>
          <b-row v-if="certificateRequest.showUploader()">
            <b-col>
              <certificate-uploader
                :certificate-request="certificateRequest"
                :entity="entity"
                @uploaded="loadEntityCertificates()"
              >
              </certificate-uploader>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import store from '@/store'
import LoadingGif from '@/components/Controls/LoadingGif'
import CertificateUploader from '@/components/Certificates/CertificateUploader'
import VerifyCertificateButton from '@/components/Certificates/VerifiyCertificateButton'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeCertificateRequest } from '@/types/certificates'

export default {
  name: 'entity-certificates',
  components: {
    VerifyCertificateButton,
    LoadingGif,
    CertificateUploader,
  },
  mixins: [BackendMixin],
  props: {
    entity: Object,
  },
  data() {
    return {
      loadingName: 'entity-certificates',
      componentName: 'entity-certificates',
      enabled: false,
      certificateRequests: [],
    }
  },
  computed: {
  },
  watch: {
    entity: function() { this.loadEntityCertificates() },
  },
  created() {
    this.loadEntityCertificates()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadEntityCertificates() {
      if (this.entity.id) {
        let backendApi = new BackendApi('get', '/certificates/api/entity-certificates/' + this.entity.id + '/')
        this.startLoading(this.loadingName)
        try {
          let resp = await backendApi.callApi()
          this.enabled = resp.data.enabled
          this.certificateRequests = resp.data.map(elt => makeCertificateRequest(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      } else {
        this.certificateRequests = []
      }
    },
    getStatusClass(certificateRequest) {
      if (certificateRequest.certificate === null) {
        if (certificateRequest.certificateType.isRequired) {
          return 'required-document'
        } else {
          return 'optional-document'
        }
      } else if (certificateRequest.certificate.verifiedOn !== null) {
        if (certificateRequest.certificate.refused) {
          return 'refused-document'
        } else if (certificateRequest.certificate.deprecated) {
          return 'deprecated-document'
        } else {
          return 'verified-document'
        }
      } else {
        return 'waiting-document'
      }
    },
    refreshOne(event) {
      for (let index = 0; index < this.certificateRequests.length; index++) {
        const certificate = this.certificateRequests[index].certificate
        if (certificate) {
          if (event.certificate.id === certificate.id) {
            this.certificateRequests[index].certificate = event.certificate
            this.certificateRequests = this.certificateRequests.concat([])
            // continue, le doc peut-être présent plusieurs fois (exemple doc perpétuel)
          }
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.block-elt {
  margin: 0 10px 20px 10px;
  padding: 5px;
  border: solid 1px #444;
  border-radius: 4px;
}
.verified-document {
  background: #19a84f;
  color: #fff;
}
.refused-document {
  background: #ff4961;
  color: #fff;
}
.deprecated-document {
  background: #c549ff;
  color: #fff;
}
.required-document {
  background: #fde184;
  color: #000;
}
.optional-document {
  background: #888;
  color: #fff;
}
.waiting-document {
  background: #3880ff;
  color: #fff;
}
.header-line {
  font-weight: bold;
}
.filenames {
  font-size: 12px;
}
.comments {
  padding: 5px;
  margin: 5px 0;
  background: #ccc;
}
.perpetual-year {
  font-weight: bold;
  color: #cd0a0a;
}
</style>
