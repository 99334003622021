<template>
  <span class="certificate-uploader">
    <div v-for="fileIndex of fileIndexes" :key="fileIndex">
      <b-row>
        <b-col>
          <input type="file" :id="getRef(fileIndex)" :ref="getRef(fileIndex)" @change="onFileUpload(fileIndex)" />
        </b-col>
      </b-row>
      <div v-if="fileIndex < files.length">
        <b-row>
          <b-col cols="6" class="file-detail">
            <div v-if="files[fileIndex]">Date: {{ lastModifiedStr(fileIndex) }}</div>
            <div v-else>Veuillez saisir un fichier</div>
          </b-col>
          <b-col cols="4" class="file-detail text-center">
            <div v-if="files[fileIndex]">Taille: {{ fileSizeStr(fileIndex) }}</div>
          </b-col>
          <b-col size="2" class="text-right">
            <a href @click.prevent="clearFile(fileIndex)">
              <i class="fa fa-window-close"></i>
            </a>
          </b-col>
        </b-row>
        <b-row
          v-if="files[fileIndex] && files[fileIndex].size >= maxSize"
          class="error-text"
        >
          <b-col>
            Le fichier dépasse la limite autorisée de {{ maxSizeStr }}
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-if="this.showExtraFiles">
      <b-row>
        <b-col>
          <div v-if="files.length < 10">
            <a href @click.prevent="addExtraFile">
              <i class="fa fa-plus-circle"></i> Ajouter un fichier complémentaire
            </a>
           </div>
           <div v-else>
             <i class="fa fa-exclamation-triangle"></i> Nombre maximum de fichiers atteint
           </div>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col class="text-right" size="4" size-xs="12">
        <a class="btn btn-primary btn-sm" @click.prevent="onUpload" :class="{ disabled: !isUploadValid(), }">
          <i class="fa fa-upload"></i> Envoyer
        </a>
      </b-col>
    </b-row>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import { fileSize, tsToDate } from '@/filters/texts'

export default {
  name: 'certificate-uploader',
  mixins: [BackendMixin],
  props: {
    certificateRequest: Object,
    entity: Object,
  },
  data() {
    return {
      errorText: '',
      files: [],
      maxSize: 1024 * 1024,
    }
  },
  computed: {
    maxSizeStr() {
      return fileSize(this.maxSize)
    },
    fileIndexes() {
      if (this.files.length === 0) {
        return [0]
      } else {
        return this.files.map((elt, index) => index)
      }
    },
    showExtraFiles() {
      if (this.files.length > 0) {
        return this.files[this.files.length - 1] !== null
      }
      return false
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    isUploadValid() {
      if (this.files.length === 0) {
        return false
      }
      for (const file of this.files) {
        if (!file) {
          return false
        }
        if (file.size >= this.maxSize) {
          return false
        }
      }
      return true
    },
    fileSizeStr(fileIndex) {
      return fileSize(this.files[fileIndex].size)
    },
    lastModifiedStr(fileIndex) {
      return tsToDate(this.files[fileIndex].lastModified)
    },
    getRef(fileIndex) {
      return this.certificateRequest.getKey() + '-file' + fileIndex
    },
    async onUpload() {
      this.errorText = ''
      if (this.isUploadValid()) {
        try {
          const entityId = this.entity.id
          const individualId = this.certificateRequest.individual.id
          const schoolYearId = this.certificateRequest.schoolYear.id
          const certificateTypeId = this.certificateRequest.certificateType.id
          const args = [entityId, schoolYearId, certificateTypeId, individualId].map(
            elt => ('' + elt)
          ).join('/')
          const url = '/certificates/api/upload-certificate/' + args + '/'
          const backendApi = new BackendApi('post', url)
          const data = new FormData()
          for (let fileIndex = 0; fileIndex < this.files.length; fileIndex++) {
            data.append('file' + fileIndex, this.files[fileIndex])
          }
          data.append('files_count', '' + this.files.length)
          const resp = await backendApi.callApi(data, 'multipart/form-data')
          this.$emit('uploaded')
          await this.addSuccess('Le document a été enregistré')
        } catch (err) {
          this.errorText = this.getErrorText(err)
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onFileUpload(fileIndex) {
      if (fileIndex <= this.files.length) {
        const ref = this.getRef(fileIndex)
        this.files[fileIndex] = this.$refs[ref][0].files[0]
        this.files = this.files.concat([])
      }
    },
    addExtraFile() {
      if (this.files.length <= 10) {
        this.files.push(null)
      }
    },
    clearFile(fileIndex) {
      if (fileIndex < this.files.length) {
        this.files.splice(fileIndex, 1)
      }
    },
  },
}
</script>
<style scoped lang="less">
  .error-text {
    padding: 2px;
    color: #e0e0e0;
    background: #cc3700;
  }
  .the-title {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: solid 1px #ccc;
    font-weight: bold;
  }
  .file-detail {
    font-size: 12px;
  }
  .certificate-uploader {
    display: block;
    margin: 5px 0;
    padding: 5px;
    border: solid 1px #ccc;
    border-radius: 4px;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
</style>
