<template>
<div
  v-if="!inscription.cancelled && hasClocking"
  class="badge badge-light badge-clocking"
  :title="duration"
>
  <!-- Pour une journée complète -->
  <span v-if="inscription.seance.lunch && inscription.seance.morning && inscription.seance.afternoon">
    <span>
      De {{inscription.arrivedAt | hour | defaultValue('?')}} à {{inscription.leftAt2 | hour | defaultValue('?')}}
    </span>
  </span>
  <span v-else>
    <div v-if="inscription.seance.morning">
      De {{inscription.arrivedAt | hour | defaultValue('?')}} à {{inscription.leftAt | hour | defaultValue('?')}}
    </div>
    <div v-if="inscription.seance.afternoon">
      De {{inscription.arrivedAt2 | hour | defaultValue('?')}} à {{inscription.leftAt2 | hour | defaultValue('?')}}
    </div>
    <div v-if="inscription.seance.evening">
      De {{inscription.arrivedAt3 | hour | defaultValue('?')}} à {{inscription.leftAt3 | hour | defaultValue('?')}}
    </div>
  </span>
</div>
</template>

<script>
import { diffTimes } from '@/utils/time'

export default {
  name: 'PresenceHours',
  props: {
    inscription: Object,
  },
  computed: {
    hasClocking() {
      return !!(
        (this.inscription.arrivedAt) ||
        (this.inscription.leftAt) ||
        (this.inscription.arrivedAt2) ||
        (this.inscription.leftAt2) ||
        (this.inscription.arrivedAt3) ||
        (this.inscription.leftAt3)
      )
    },
    duration() {
      let value = 0
      if (this.inscription.seance.lunch && this.inscription.seance.morning && this.inscription.seance.afternoon) {
        if (this.inscription.arrivedAt && this.inscription.leftAt2) {
          value = diffTimes(this.inscription.leftAt2, this.inscription.arrivedAt)
        }
      } else {
        if (this.inscription.seance.morning) {
          if (this.inscription.arrivedAt && this.inscription.leftAt) {
            value += diffTimes(this.inscription.leftAt, this.inscription.arrivedAt)
          }
        }
        if (this.inscription.seance.afternoon) {
          if (this.inscription.arrivedAt2 && this.inscription.leftAt2) {
            value += diffTimes(this.inscription.leftAt2, this.inscription.arrivedAt2)
          }
        }
        if (this.inscription.seance.evening) {
          if (this.inscription.arrivedAt3 && this.inscription.leftAt3) {
            value += diffTimes(this.inscription.leftAt3, this.inscription.arrivedAt3)
          }
        }
      }
      if (value) {
        value = Math.round(value / 36) / 100
        return value + 'h'
      } else {
        return ''
      }
    },
  },
}
</script>

<style scoped>
.badge-clocking {
  margin: 3px 0;
  display: inline-block;
}
</style>
