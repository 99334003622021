<template>
  <span class="nursery-cancel-contract-modal">
    <b-modal
      :id="modalId"
      cancel-title="Annuler"
      @ok="onConfirm"
      @cancel="onCancel"
      :ok-disabled="readOnly"
      ok-variant="primary"
      ok-title="Enregistrer"
      @show="init()"
    >
      <template v-slot:modal-title v-if="contract">
        <i class="fa fa-calendar-day"></i> Modification de contrat
      </template>
      <div v-if="contract" class="row-line">
        {{ contract.individual.lastAndFirstName() }}
      </div>
      <div v-if="contract" class="row-line">
        Du {{ contract.startDate | dateToString }} au {{ contract.endDate | dateToString }}
      </div>
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div v-if="contract && !isLoading(loadingName)">
        <b-form-group label="Action souhaitée" laber-for="cancelOption">
          <b-select id="cancelOption" v-model="cancelOption">
            <b-select-option :value="0"></b-select-option>
            <b-select-option :value="1">Annuler le contrat</b-select-option>
            <b-select-option :value="2">Changer les dates de contrat</b-select-option>
            <b-select-option :value="3">Mettre à jour la participation familiale</b-select-option>
            <b-select-option :value="4">Mettre à jour les périodes d'adaptation</b-select-option>
          ></b-select>
        </b-form-group>
        <b-row v-if="cancelOption > 0">
          <b-col>
            <b-form-group
              label-for="startDate"
              :label="startDateLabel"
              :description="startDateDescription"
            >
              <b-input
                type="date"
                id="startDate"
                v-model="startDate"
              ></b-input>
            </b-form-group>
          </b-col>
          <b-col v-if="cancelOption > 1">
            <b-form-group
              label-for="endDate"
              :label="endDateLabel"
              :description="endDateDescription"
            >
              <b-input
                type="date"
                id="endDate"
                v-model="endDate"
              ></b-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="cancelOption === 3">
          <b-col>
            <div v-if="hasContractUpdate" class="warning-text">
              Un avenant sera créé
            </div>
          </b-col>
        </b-row>
        <b-row v-if="cancelOption === 4">
          <b-col>
            <b-form-group
              label-for="adaptation"
              label="Adaptation"
            >
              <b-select v-model="adaptation" id="adaptation" name="adaptation">
                <b-select-option
                  :value="elt.id"
                  v-for="elt of adaptations"
                  :key="elt.id"
                >
                  {{ elt.name }}
                </b-select-option>
              </b-select>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import moment from 'moment'
import { mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import { BackendMixin } from '@/mixins/backend'
import { dateToString } from '@/filters/texts'
import { makeNurseryContract, NurseryAdaptation } from '@/types/nursery'
import { BackendApi } from '@/utils/http'
import { makeChoice } from '@/types/base'

export default {
  name: 'NurserySetInscriptionClockingModal',
  components: {
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    contract: Object,
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      loadingName: 'NurseryCancelContractModal',
      errorText: '',
      reasonText: '',
      cancelOption: 0,
      adaptation: 0,
      adaptations: [
        makeChoice({ id: NurseryAdaptation.None, name: 'Non', }),
        makeChoice({ id: NurseryAdaptation.WithoutParents, name: 'Sans les parents', }),
        makeChoice({ id: NurseryAdaptation.WithParents, name: 'Avec les parents', })
      ],
    }
  },
  watch: {
    cancelOption: function() {
      this.init()
    },
  },
  computed: {
    modalId() {
      if (this.id) {
        return this.id
      } else {
        return 'bv-nursery-cancel-contract-modal'
      }
    },
    startDateLabel() {
      if (this.cancelOption === 1) {
        return 'Annuler les inscriptions à partir du'
      } else if (this.cancelOption === 2) {
        return 'Premier jour'
      } else if (this.cancelOption === 3) {
        return 'Premier jour'
      } else if (this.cancelOption === 4) {
        return 'Premier jour'
      }
      return ''
    },
    endDateLabel() {
      if (this.cancelOption === 1) {
        return 'Annuler les inscriptions à partir du'
      } else if (this.cancelOption === 2) {
        return 'Dernier jour'
      } else if (this.cancelOption === 3) {
        return 'Dernier jour'
      } else if (this.cancelOption === 4) {
        return 'Dernier jour'
      }
      return ''
    },
    startDateDescription() {
      if (this.cancelOption === 1) {
        return 'Toutes les inscriptions à partir de cette date seront annulées'
      } else if (this.cancelOption === 2) {
        return 'Les inscriptions avant cette date seront annulées'
      } else if ((this.cancelOption === 3) || (this.cancelOption === 4)) {
        return 'Les inscriptions avant cette date ne seront pas modifiées'
      }
      return ''
    },
    endDateDescription() {
      if (this.cancelOption === 1) {
        return 'Toutes les inscriptions à partir de cette date seront annulées'
      } else if (this.cancelOption === 2) {
        return 'Les inscriptions après cette date seront annulées'
      } else if ((this.cancelOption === 3) || (this.cancelOption === 4)) {
        return 'Les inscriptions après cette date ne seront pas modifiées'
      }
      return ''
    },
    readOnly() {
      let readOnly = (
        !this.hasPerm('nursery.change_nurserycontract') ||
        (!this.startDate) ||
        (!this.cancelOption)
      )
      if (!readOnly) {
        if (this.cancelOption === 2) {
          readOnly = !this.endDate
        }
        if (this.cancelOption === 3) {
          readOnly = !this.endDate
        }
      }
      return readOnly
    },
    today() {
      return moment().format('YYYY-MM-DD')
    },
    hasContractUpdate() {
      return (
        (this.startDate > this.contract.startDate) ||
        (this.endDate > this.contract.endDate)
      )
    },
  },
  methods: {
    ...mapActions(['addSuccess']),
    async onConfirm(event) {
      event.preventDefault()
      this.errorText = ''
      if (this.contract && !this.readOnly && this.cancelOption) {
        try {
          const url = '/nursery/api/cancel-contract/' + this.contract.id + '/'
          const backendApi = new BackendApi('post', url)
          const cancelOption = +this.cancelOption
          const data = {
            'action': cancelOption,
            'start_date': dateToString(this.startDate, 'YYYY-MM-DD'),
            'end_date': this.endDate ? dateToString(this.endDate, 'YYYY-MM-DD') : null,
            'adaptation': this.adaptation,
          }
          const resp = await backendApi.callApi(data)
          const contract = makeNurseryContract(resp.data)
          this.$emit('confirmed', { contract: contract, })
          let text = 'Le contrat a été modifié'
          if (cancelOption === 1) {
            text = 'Le contrat a été annulé'
          }
          await this.addSuccess(text)
          this.$bvModal.hide(this.modalId)
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    onCancel() {
    },
    init() {
      if (this.cancelOption === 1) {
        this.startDate = this.today
        this.endDate = ''
      } else if (this.cancelOption > 1) {
        this.startDate = this.contract.startDate
        this.endDate = this.contract.endDate
      }
    },
  },
}
</script>
<style lang="less" scoped>
</style>
