import { Entity, Individual, makeEntity, makeIndividual } from '@/types/people'
import { SaleWithInvoice, makeSaleWithInvoice } from '@/types/payments'
import { dateToString } from '@/filters/texts'
import { makeSchoolYear, SchoolYear } from '@/types/schools'
import json = Mocha.reporters.json

class PublicationTitle {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function makePublicationTitle(jsonData: any = null): PublicationTitle {
  if (jsonData === null) {
    jsonData = {}
  }
  return new PublicationTitle(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

class PublicationType {
  constructor(
    public id: number,
    public name: string,
    public publicationTitle: PublicationTitle,
    public price: number,
    public sendingPrice: number,
    public fabPrice: number,
    public depositPriceRate: number,
    public isPublication: boolean,
    public hasArticles: boolean,
    public hasAuthors: boolean,
    public allowSubscriptions: boolean,
    public next: number,
    public publicationsCount: number,
    public subscriptionsCount: number
  ) {
  }
}

export function makePublicationType(jsonData: any = null): PublicationType {
  if (jsonData === null) {
    jsonData = {}
  }
  return new PublicationType(
    jsonData.id || 0,
    jsonData.name || '',
    makePublicationTitle(jsonData.publication_title),
    jsonData.price,
    jsonData.sending_price,
    jsonData.fab_price,
    jsonData.deposit_price_rate,
    !!jsonData.is_publication,
    !!jsonData.has_articles,
    !!jsonData.has_authors,
    !!jsonData.allow_subscriptions,
    jsonData.next || 0,
    jsonData.publications_count || 0,
    jsonData.subscriptions_count || 0
  )
}

class PublicationAuthor {
  constructor(
    public id: number,
    public individual: Individual,
    public createdOn: Date
  ) {
  }
}

export function makePublicationAuthor(jsonData: any = null): PublicationAuthor {
  if (jsonData === null) {
    jsonData = {}
  }
  return new PublicationAuthor(
    jsonData.id || 0,
    makeIndividual(jsonData.individual),
    jsonData.created_on
  )
}

class PublicationKeyword {
  constructor(
    public id: number,
    public keyword: string
  ) {
  }
}

export function makePublicationKeyword(jsonData: any = null): PublicationKeyword {
  if (jsonData === null) {
    jsonData = {}
  }
  return new PublicationKeyword(
    jsonData.id || 0,
    jsonData.keyword || ''
  )
}

class PublicationArticle {
  constructor(
    public id: number,
    public title: string,
    public comments: string,
    public notes: string,
    public authors: PublicationAuthor[],
    public keywords: PublicationKeyword[]
  ) {
  }
}

export function makePublicationArticle(jsonData: any = null): PublicationArticle {
  if (jsonData === null) {
    jsonData = {}
  }
  let keywords = []
  let authors = []
  if (jsonData.keywords) {
    keywords = jsonData.keywords.map(makePublicationKeyword)
  }
  if (jsonData.authors) {
    authors = jsonData.authors.map(makePublicationAuthor)
  }
  return new PublicationArticle(
    jsonData.id || 0,
    jsonData.title || '',
    jsonData.comments || '',
    jsonData.notes || '',
    authors,
    keywords
  )
}

class Publication {
  constructor(
    public id: number,
    public publicationType: PublicationType,
    public number: number|null,
    public title: string,
    public publishedOn: Date|null,
    public price: number,
    public sendingPrice: number,
    public authorPrice: number,
    public fabPrice: number,
    public depositPrice: number,
    public isAvailable: boolean,
    public pagesCount: number,
    public notes: string,
    public articles: PublicationArticle[],
    public authors: PublicationAuthor[],
    public copiesCount: number,
    public copiesQuantity: number,
    public soldCopiesCount: number,
    public soldCopiesQuantity: number,
    public depositCopiesCount: number,
    public depositCopiesQuantity: number
  ) {
  }

  public fullName(): string {
    let words: string[] = [this.publicationType.name]
    if (this.number) {
      words.push('N°' + this.number)
    }
    if (this.title) {
      words.push(this.title)
    }
    if (this.publishedOn) {
      words.push(dateToString(this.publishedOn, 'LL'))
    }
    return words.join(' - ')
  }

  public shortName(): string {
    let words: string[] = []
    if (this.number) {
      words.push('N°' + this.number)
    }
    if (this.title) {
      words.push(this.title)
    }
    if (this.publishedOn) {
      words.push(dateToString(this.publishedOn, 'LL'))
    }
    return words.join(' - ')
  }
}

export function makePublication(jsonData: any = null): Publication {
  if (jsonData === null) {
    jsonData = {}
  }
  let articles = []
  if (jsonData.articles) {
    articles = jsonData.articles.map(makePublicationArticle)
  }
  let authors = []
  if (jsonData.authors) {
    authors = jsonData.authors.map(makePublicationAuthor)
  }
  return new Publication(
    jsonData.id || 0,
    makePublicationType(jsonData.publication_type),
    jsonData.number || null,
    jsonData.title || '',
    jsonData.published_on || null,
    jsonData.price || 0,
    jsonData.sending_price || 0,
    jsonData.author_price || 0,
    jsonData.fab_price || 0,
    jsonData.deposit_price || 0,
    !!jsonData.is_available,
    jsonData.pages_count || 0,
    jsonData.notes || '',
    articles,
    authors,
    jsonData.copies_count,
    jsonData.copies_quantity,
    jsonData.sold_copies_count,
    jsonData.sold_copies_quantity,
    jsonData.deposit_copies_count,
    jsonData.deposit_copies_quantity
  )
}

class Subscription {
  constructor(
    public id: number,
    public publicationType: PublicationType,
    public fromNumber: number,
    public toNumber: number,
    public publishedOn: Date|null,
    public price: number,
    public sendingPrice: number,
    public authorPrice: number,
    public fabPrice: number,
    public depositPrice: number,
    public isAvailable: boolean,
    public notes: string,
    public subscriptorsCount: number,
    public subscriptorsQuantity: number
  ) {
  }

  public fullName(): string {
    let words: string[] = [this.publicationType.name]
    words.push('Du n°' + this.fromNumber + ' au n°' + this.toNumber)
    if (this.publishedOn) {
      words.push(dateToString(this.publishedOn, 'LL'))
    }
    return words.join(' - ')
  }

  public shortName(): string {
    let words: string[] = []
    words.push('Du n°' + this.fromNumber + ' au n°' + this.toNumber)
    if (this.publishedOn) {
      words.push(dateToString(this.publishedOn, 'LL'))
    }
    return words.join(' - ')
  }
}

export function makeSubscription(jsonData: any = null): Subscription {
  if (jsonData === null) {
    jsonData = {}
  }
  return new Subscription(
    jsonData.id || 0,
    makePublicationType(jsonData.publication_type),
    jsonData.from_number || 0,
    jsonData.to_number || 0,
    jsonData.published_on || null,
    jsonData.price || 0,
    jsonData.deposit_price || 0,
    jsonData.sending_price || 0,
    jsonData.author_price || 0,
    jsonData.fab_price || 0,
    !!jsonData.is_available,
    jsonData.notes || '',
    jsonData.subscriptors_count,
    jsonData.subscriptors_quantity
  )
}

class PublicationDeposit {
  constructor(
    public id: number,
    public depositNumber: number,
    public createdOn: Date,
    public depositOn: Date,
    public entity: Entity,
    public totalPrice: number,
    public invoicePrice: number,
    public paidPrice: number,
    public copiesCount: number
  ) {
  }
}

export function makePublicationDeposit(jsonData: any = null): PublicationDeposit {
  if (jsonData === null) {
    jsonData = {}
  }
  return new PublicationDeposit(
    jsonData.id || 0,
    jsonData.deposit_number || 0,
    jsonData.created_on,
    jsonData.deposit_on,
    makeEntity(jsonData.entity),
    jsonData.total_price || 0,
    jsonData.invoice_price || 0,
    jsonData.paid_price || 0,
    jsonData.copies_count || 0
  )
}

class PublicationCopy {
  constructor(
    public id: number,
    public createdOn: Date,
    public publication: Publication,
    public entity: Entity,
    public deposit: PublicationDeposit|null,
    public sentOn: Date|null,
    public sending: boolean,
    public waiting: boolean,
    public quantity: number,
    public unitPrice: number,
    public sendingPrice: number,
    public discount: number,
    public totalPrice: number,
    public paidPrice: number,
    public sale: SaleWithInvoice|null,
    public schoolYear: SchoolYear
  ) {
  }
}

export function makePublicationCopy(jsonData: any = null): PublicationCopy {
  if (jsonData === null) {
    jsonData = {}
  }
  return new PublicationCopy(
    jsonData.id || 0,
    jsonData.created_on,
    makePublication(jsonData.publication),
    makeEntity(jsonData.entity),
    jsonData.deposit ? makePublicationDeposit(jsonData.deposit) : null,
    jsonData.sent_on,
    !!jsonData.sending,
    !!jsonData.waiting,
    +jsonData.quantity,
    +jsonData.unit_price,
    +jsonData.sending_price,
    +jsonData.discount,
    +jsonData.total_price,
    +jsonData.paid_price,
    jsonData.sale ? makeSaleWithInvoice(jsonData.sale) : null,
    makeSchoolYear(jsonData.school_year)
  )
}

class Subscriptor {
  constructor(
    public id: number,
    public createdOn: Date,
    public subscription: Subscription,
    public entity: Entity,
    public sending: boolean,
    public quantity: number,
    public unitPrice: number,
    public sendingPrice: number,
    public discount: number,
    public totalPrice: number,
    public sale: SaleWithInvoice|null,
    public schoolYear: SchoolYear
  ) {
  }
}

export function makeSubscriptor(jsonData: any = null): Subscriptor {
  if (jsonData === null) {
    jsonData = {}
  }
  return new Subscriptor(
    jsonData.id || 0,
    jsonData.created_on,
    makeSubscription(jsonData.subscription),
    makeEntity(jsonData.entity),
    !!jsonData.sending,
    +jsonData.quantity,
    +jsonData.unit_price,
    +jsonData.sending_price,
    +jsonData.discount,
    +jsonData.total_price,
    jsonData.sale ? makeSaleWithInvoice(jsonData.sale) : null,
    makeSchoolYear(jsonData.school_year)
  )
}
