<template>
  <span v-if="hasPerm('nursery.view_nurserycontract')">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <a
      :class="{ disabled: isLoading(loadingName)}"
      class="btn btn-secondary btn-sm"
      href download
      @click.prevent="downloadContract"
    >
      <i class="fa fa-file-pdf"></i> Télécharger
    </a>
  </span>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { openDocument } from '@/utils/http'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'nursery-contract-download',
  components: {
    LoadingGif,
  },
  mixins: [
    BackendMixin
  ],
  props: {
    contract: Object,
  },
  data() {
    return {
      loadingName: 'nursery-contract-download',
    }
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async downloadContract() {
      if (!this.isLoading(this.loadingName)) {
        this.startLoading(this.loadingName)
        const docUrl = '/nursery/contract/<key>/pdf/'
        const docSlug = '' + '' + this.contract.id
        try {
          await openDocument(docUrl, docSlug)
          this.$emit('opened', { contract: this.contract, })
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
