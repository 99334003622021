<template>
  <div>
    <div class="filter-indicator-line">
      <div v-show="!allActive">
        <span v-for="weekDay in daysOfTheWeek" :key="weekDay.id">
          <span class="filter-badge" :class="isWeekDayActive(weekDay) ? 'active' : 'disabled'">
            {{ weekDay.name }}
          </span>
        </span>
        <span v-for="dayTime in timesOfTheDay" :key="dayTime.id">
          <span class="filter-badge" :class="isDayTimeActive(dayTime) ? 'active' : 'disabled'">
            {{ dayTime.name }}
          </span>
        </span>
      </div>
      <span class="filter-badge active" v-if="dateFrom && dateFrom !== '-'">
        <span v-if="dateTo && dateTo !== '-'">Du</span>
        <span v-else>À partir du</span>
        {{ dateFrom }}
      </span>
      <span class="filter-badge active" v-if="dateTo && dateTo !== '-'">
        <span v-if="dateFrom && dateFrom !== '-'">Au</span>
        <span v-else>Jusqu'au</span>
        {{ dateTo }}
      </span>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import moment from 'moment'

export default {
  name: 'SeancesFiltersIndicator',
  props: {
    weekDays: Array,
    dayTimes: Array,
    dateFrom: String,
    dateTo: String,
  },
  data() {
    return {
      visible: false,
    }
  },
  watch: {
    weekDays: () => {},
    dayTimes: () => {},
    allActive: () => {},
    dateFrom: () => {},
    dateTo: () => {},
  },
  computed: {
    daysOfTheWeek() {
      return [ ...Array(7).keys() ].map(
        index => {
          return { id: index, name: moment.weekdays(true, index), }
        }
      )
    },
    timesOfTheDay() {
      return [
        { id: -1, name: 'Matin', },
        { id: -2, name: 'Repas', },
        { id: -3, name: 'Après-Midi', },
        { id: -4, name: 'Soirée', }
      ]
    },
    allActive() {
      return this.allWeekDaysActive && this.allDayTimesActive
    },
    allWeekDaysActive() {
      // True if all are True
      if (this.weekDays) {
        return this.weekDays.filter(elt => !elt).length === 0
      }
      return true
    },
    allDayTimesActive() {
      // True if all are True
      if (this.dayTimes) {
        return this.dayTimes.filter(elt => !elt).length === 0
      }
      return true
    },
  },
  methods: {
    isWeekDayActive(weekDay) {
      // True if all are True
      if (this.weekDays) {
        return this.weekDays[weekDay.id]
      }
      return true
    },
    isDayTimeActive(dayTime) {
      // True if all are True
      let index = -(dayTime.id + 1)
      if (this.dayTimes) {
        return this.dayTimes[index]
      }
      return true
    },
  },
}
</script>
<style scoped lang="less">
  .filter-indicator-line {
    padding: 2px 10px;
  }
  .filter-badge {
    font-size: 12px;
    padding: 2px 5px;
    border: solid 1px #ccc;
    border-radius: 4px;
    margin-right: 2px;
    white-space: nowrap;
    display: inline-block;
  }
  .filter-badge.active {
    background: #444;
    color: #fff;
  }
  .filter-badge.disabled {
    background: #fff;
    color: #d0d0d0;
  }
</style>
