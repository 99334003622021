<template>
  <div v-if="entity !== null && entity.id && hasPerm('portal.view_account')">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div class="field-group-block" v-if="enabled && !isLoading(loadingName)">
      <div v-if="individuals">
        <div class="content-headline">
          <b-row>
            <b-col>
              <div class="header-bar">
                <b-row>
                  <b-col md="8">
                    <h3>Portail Famille</h3>
                  </b-col>
                  <b-col md="4" class="text-right">
                    <a
                      class="btn btn-primary edit-button btn-sm"
                      href
                      @click.prevent="onEdit"
                      v-if="!showForm && !readOnly && hasPerm('portal.add_account')"
                      :class="editDisabled ? 'disabled' : ''"
                    >
                      <i class="fa fa-edit"></i> Modifier
                    </a>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-form @submit.prevent="onSave" v-if="showForm">
          <div v-if="individuals.length === 0" class="field-line">
            Aucun membre de la famille ne peut être contacté par email
          </div>
          <div v-for="individual of individuals" :key="individual.id" class="field-line">
            <b-row v-if="individual.email">
              <b-col cols="4">
                {{ individual.email }}
              </b-col>
              <b-col cols="4">
                {{ individual.firstAndLastName() }}
              </b-col>
              <b-col cols="4" class="text-right">
                <span v-if="isEmailAlreadyUsed(individual)">
                  Cet email est déjà utilisé
                </span>
                <span v-else>
                  <b-form-checkbox
                    :id="'active' + individual.id"
                    :checked="hasAccount(individual)"
                    :name="'active' + individual.id"
                    :value="true"
                    :unchecked-value="false"
                    @change="setAccount(individual, $event)"
                  >
                    Dispose d'un compte
                  </b-form-checkbox>
                </span>
              </b-col>
            </b-row>
          </div>
          <b-row class="buttons-bar">
            <b-col class="text-left">
            </b-col>
            <b-col class="text-right">
              <a class="btn btn-secondary btn-cancel" href @click.prevent="onCancel">Annuler</a>
              <b-button type="submit" variant="primary">Enregistrer</b-button>
            </b-col>
          </b-row>
        </b-form>
        <div v-if="!showForm">
          <div v-if="accounts.length === 0" class="field-line">
            Aucun compte existant
          </div>
          <div v-for="account of accounts" :key="account.id" class="field-line">
            <b-row>
              <b-col :cols="(member === null) ? 4 : 12">{{ account.individual.firstAndLastName() }}</b-col>
              <b-col :cols="(member === null) ? 4 : 12">{{ account.individual.email }}</b-col>
              <b-col :cols="(member === null) ? 4 : 12" class="text-right">
                <span v-if="account.user.isActive">Compte actif</span>
                <span v-else-if="hasPerm('portal.add_account')">
                  <a
                    v-if="!notifiedToday(account) && !readOnly"
                    class="btn btn-secondary btn-sm"
                    @click.prevent="sendActivationEmail(account)"
                  >
                    <i class="fa fa-send"></i> Envoyer l'email d'activation
                  </a>
                  <div class="small2">
                    <span v-if="!account.notifiedOn">Pas encore notifié</span>
                    <span v-else>Notifié le {{ account.notifiedOn | dateToString('lll') }}</span>
                  </div>
                </span>
                <span v-else>
                  Compte à activer
                </span>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendMixin } from '@/mixins/backend'
import { dateToString } from '@/filters/texts'
import store from '@/store'
import { makeAccount } from '@/types/portal'
import { makeYouthHome } from '@/types/youth'
import { BackendApi } from '@/utils/http'

export default {
  name: 'entity-accounts',
  components: {
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    entity: Object,
    member: Object,
    readOnly: Boolean,
  },
  data() {
    return {
      loadingName: 'entity-accounts',
      componentName: 'entity-accounts',
      enabled: false,
      youthHomes: [],
      accounts: [],
      accountsMap: {},
    }
  },
  computed: {
    showForm() {
      return (store.getters.editMode === this.componentName)
    },
    editDisabled() {
      return (store.getters.editMode !== '' && store.getters.editMode !== this.componentName)
    },
    individuals() {
      return this.entity.getMainIndividuals().filter(elt => elt.email)
    },
  },
  watch: {
    entity: function() { this.loadEntityAccounts() },
    readOnly: function() {},
  },
  created() {
    this.loadEntityAccounts()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading', 'setEditMode']),
    onEdit() {
      if (!this.editDisabled) {
        this.setEditMode(this.componentName)
      }
    },
    onCancel() {
      this.setEditMode('')
    },
    async onSave() {
      const data = {
        'individuals': this.individuals.map(elt => elt.id).filter(indId => {
          return ((this.accountsMap.hasOwnProperty(indId)) && (this.accountsMap[indId]))
        }),
      }
      let backendApi = new BackendApi('post', '/api/create-entity-accounts/' + this.entity.id + '/')
      this.setEditMode('')
      this.startLoading(this.loadingName)
      try {
        let resp = await backendApi.callApi(data)
        if (resp.data.messages.length) {
          await this.addSuccess(resp.data.messages.join('. '))
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
      await this.loadEntityAccounts()
    },
    async loadEntityAccounts() {
      if (this.entity.id) {
        let backendApi = new BackendApi('get', '/api/entity-accounts/' + this.entity.id + '/')
        this.startLoading(this.loadingName)
        try {
          let resp = await backendApi.callApi()
          this.enabled = resp.data.enabled
          this.youthHomes = resp.data.youth_homes.map(elt => makeYouthHome(elt))
          this.accounts = resp.data.accounts.map(elt => makeAccount(elt))
          this.accountsMap = {}
          for (let account of this.accounts) {
            this.accountsMap[account.individual.id] = true
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      } else {
        this.enabled = false
        this.youthHomes = []
        this.accounts = []
      }
    },
    hasAccount(individual) {
      if (this.accountsMap.hasOwnProperty(individual.id)) {
        return this.accountsMap[individual.id]
      }
      return false
    },
    setAccount(individual, value) {
      this.accountsMap[individual.id] = value
      this.accountsMap = { ...this.accountsMap, }
    },
    async sendActivationEmail(account) {
      try {
        const backendApi = new BackendApi('post', '/api/send-account-activation/')
        const resp = await backendApi.callApi({ account: account.id, })
        account.notifiedOn = new Date()
        await this.addSuccess(resp.data.message)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    notifiedToday(account) {
      if (account.notifiedOn === null) {
        return false
      } else {
        const dayFormat = 'DD/MM/YYYY'
        return dateToString(account.notifiedOn, dayFormat) === moment().format(dayFormat)
      }
    },
    isEmailAlreadyUsed(individual) {
      if (individual.email) {
        const knownEmails = this.individuals.map(elt => elt.email)
        const emailIndex = knownEmails.indexOf(individual.email)
        return this.individuals[emailIndex].id !== individual.id
      }
      return false
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
