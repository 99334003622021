<template>
  <div v-if="entity !== null && entity.id && isPublicationActive && canView">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div class="field-group-block" v-if="!isLoading(loadingName)">
      <div class="content-headline">
        <b-row>
          <b-col>
            <div class="header-bar">
              <b-row>
                <b-col>
                  <h3>{{ publicationTitle }}</h3>
                </b-col>
                <b-col class="text-right">
                  <a
                    class="btn btn-secondary"
                    href
                    v-if="activeTab.name === 'copies' && hasPerm('publications.add_publicationcopy')"
                    @click.prevent="showAddCopy()"
                  >
                    <i class="fa fa-plus"></i> Nouvel exemplaire
                  </a>
                  <a
                    class="btn btn-secondary"
                    href
                    v-if="activeTab.name === 'subscriptions' && hasPerm('publications.add_subscriptor')"
                    @click.prevent="showAddSubscriptor()"
                  >
                    <i class="fa fa-plus"></i> Nouvel abonnement
                  </a>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="field-line field-line-no-border">
        <tabs-bar enabled :tabs="tabs" :active="activeTab" @change="tabChanged"></tabs-bar>
      </div>
      <div v-if="activeTab.name === 'copies'">
        <div class="field-line field-line-no-border">
          <div v-if="copies.length === 0" class="empty-text">
            Aucun exemplaire
          </div>
          <div v-else>
            <x-table
              id="copies"
              class="small"
              :columns="copyColumns"
              :items="copyItems"
              :show-footer="true"
            ></x-table>
          </div>
        </div>
      </div>
      <div v-if="activeTab.name === 'subscriptions'">
        <div class="field-line field-line-no-border">
          <div v-if="subscriptions.length === 0" class="empty-text">
            Aucun bonnement
          </div>
          <div v-else>
            <x-table
              id="subscriptions"
              class="small"
              :columns="subscriptionColumns"
              :items="subscriptionItems"
              :show-footer="true"
            ></x-table>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal
      name="delete-entity-publication-copy"
      icon="fa fa-trash"
      :object="selectedCopy"
      title="Suppression d'un exemplaire"
      :text="deleteCopyText"
      @confirmed="onConfirmDelete"
    ></confirm-modal>
    <payment-modal
      modal-id="pay-entity-copy-modal"
      :entity="entity"
      :invoice="paymentInvoice"
      @paid="loadEntityItems()"
    >
    </payment-modal>
    <add-publication-copy-modal
      modal-id="bv-add-entity-publication-copy"
      :entity="entity"
      v-if="hasPerm('publications.add_publicationcopy')"
      @done="loadEntityItems()"
    ></add-publication-copy-modal>
    <add-subscriptor-modal
      :entity="entity"
      v-if="hasPerm('publications.add_subscriptor')"
      modal-id="bv-add-entity-subscriptor"
      @done="loadEntityItems"
    ></add-subscriptor-modal>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import TabsBar from '@/components/Controls/TabsBar.vue'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { TabItem } from '@/types/tabs'
import { BackendApi } from '@/utils/http'
import { makePublicationCopy, makeSubscriptor } from '@/types/publications'
import XTable from '@/components/Controls/Table/Table.vue'
import router from '@/router'
import PaymentModal from '@/components/Payments/PaymentModal.vue'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import { dateToString } from '@/filters/texts'
import AddPublicationCopyModal from '@/components/Publications/AddPublicationCopyModal.vue'
import AddSubscriptorModal from '@/components/Publications/AddSubscriptorModal.vue'

export default {
  name: 'entity-publications',
  components: {
    AddSubscriptorModal,
    AddPublicationCopyModal,
    ConfirmModal,
    PaymentModal,
    XTable,
    TabsBar,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    entity: Object,
  },
  data() {
    return {
      loadingName: 'entity-publications',
      copies: [],
      subscriptions: [],
      loaded: false,
      activeTab: null,
      copyColumns: [],
      subscriptionColumns: [],
      paymentInvoice: null,
      selectedCopy: null,
    }
  },
  computed: {
    isPublicationActive() {
      return store.getters.config.isPublicationActive
    },
    canView() {
      return this.hasOnePerm(
        ['publications.view_publicationcopy', 'publications.view_subscriptor']
      )
    },
    tabs() {
      const tabs = []
      if (this.hasPerm('publications.view_publicationcopy')) {
        const label1 = '' + this.copies.length + ' exemplaire' + ((this.copies.length > 1) ? 's' : '')
        tabs.push(new TabItem('copies', label1, 'fas fa-book-reader'))
      }
      if (this.hasPerm('publications.view_subscriptor')) {
        const label2 = '' + this.subscriptions.length + ' abonnement' + ((this.subscriptions.length > 1) ? 's' : '')
        tabs.push(new TabItem('subscriptions', label2, 'fa fa-envelopes-bulk'))
      }
      return tabs
    },
    publicationTitle() {
      return store.getters.config.publicationTitle
    },
    copyItems() {
      return this.copies.map(this.makeCopyItem)
    },
    subscriptionItems() {
      return this.subscriptions.map(this.makeSubscriptionItem)
    },
    deleteCopyText() {
      let text = 'Êtes-vous sûr de supprimer l\'exemplaire'
      if (this.selectedCopy) {
        text += ' de ' + this.selectedCopy.publication.title
        text += ' du ' + dateToString(this.selectedCopy.createdOn, 'DD/MM/YYYY') + '?'
      }
      return text
    },
  },
  watch: {
    entity: function() { this.onCreated() },
  },
  async created() {
    this.copyColumns = this.getCopyColumns()
    this.subscriptionColumns = this.getSubscriptionsColumns()
    await this.onCreated()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading', 'setEditMode']),
    async onCreated() {
      this.tabChanged(this.tabs[0])
      await this.loadEntityItems()
    },
    tabChanged(tab) {
      this.activeTab = tab
    },
    async loadEntityItems() {
      this.loaded = false
      if (this.entity.id) {
        let backendApi = new BackendApi('get', '/publications/api/entity-items/' + this.entity.id + '/')
        this.startLoading(this.loadingName)
        try {
          let resp = await backendApi.callApi()
          this.copies = resp.data.copies.map(makePublicationCopy)
          this.subscriptions = resp.data.subscriptions.map(makeSubscriptor)
          this.loaded = true
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      } else {
        this.copies = []
        this.subscriptions = []
      }
    },
    getCopyColumns() {
      const cols = [
        {
          name: 'publicationType',
          label: 'Type',
        },
        {
          name: 'publication',
          label: 'Publication',
        },
        {
          name: 'createdOn',
          label: 'le',
          dateFormat: 'LL',
        },
        {
          name: 'type',
          label: 'Type',
        },
        {
          name: 'sending',
          label: 'envoi',
        },
        {
          name: 'sentOn',
          label: 'envoyé le',
          dateFormat: 'DD/MM/YYYY',
        },
        {
          name: 'quantity',
          label: 'Quantité',
          number: true,
          colFooterSum: true,
        },
        {
          name: 'unitPrice',
          label: 'Prix unitaire',
          currency: true,
        },
        {
          name: 'discount',
          label: 'Réduction',
          currency: true,
        },
        {
          name: 'sendingPrice',
          label: 'Prix envoi',
          currency: true,
        },
        {
          name: 'totalPrice',
          label: 'Prix total',
          currency: true,
          colFooterSum: true,
        },
        {
          name: 'invoicing',
          label: '',
          hideFilter: false,
          isLink: item => {
            return (item.invoice)
          },
          linkUrl: item => {
            if (item.invoice) {
              return router.resolve(
                { name: 'invoice-detail', params: { invoiceId: '' + item.invoice.id, }, }
              ).href
            }
          },
          contentCallback: (col, item) => {
            if (item.invoice) {
              return '<span class="badge ' + item.invoice.getStyle() + '">' + item.invoicing + '</span>'
            } else {
              return '<span class="badge todo-invoice">' + item.invoicing + '</span>'
            }
          },
        },
        {
          name: 'pay',
          label: '',
          hideFilter: true,
          onClick: item => {
            if (item.pay) {
              this.payInvoice(item)
            }
          },
          isLink: item => {
            return item.pay
          },
          contentCallback: (col, item) => {
            if (item.pay) {
              return '<span class="btn btn-secondary btn-sm">' +
                '<i class="fa fa-money-bill")></i>' +
                '</span>'
            }
            return ''
          },
        }
      ]
      if (this.hasPerm('publications.delete_publicationcopy')) {
        cols.push(
          {
            name: 'delete',
            label: '  ',
            hideFilter: true,
            alignRight: true,
            maxWidth: '25px',
            isLink: item => {
              return (item)
            },
            onClick: item => {
              this.onDeleteCopy(item)
            },
          }
        )
      }
      return cols
    },
    getSubscriptionsColumns() {
      return [
        {
          name: 'subscriptionType',
          label: 'Type',
        },
        {
          name: 'fromNumber',
          label: 'Du N°',
          number: true,
        },
        {
          name: 'toNumber',
          label: 'Au N°',
          number: true,
        },
        {
          name: 'createdOn',
          label: 'le',
          dateFormat: 'LL',
        },
        {
          name: 'sending',
          label: 'envoi',
        },
        {
          name: 'sentOn',
          label: 'envoyé le',
          dateFormat: 'DD/MM/YYYY',
        },
        {
          name: 'quantity',
          label: 'Quantité',
          number: true,
          colFooterSum: true,
        }
      ]
    },
    makeCopyItem(copy) {
      let invoice = null
      let invoicing = null
      let pay = 0
      if ((!copy.schoolYear.isArchived) && copy.sale) {
        invoice = copy.sale.invoice
        if (invoice) {
          pay = invoice.toBePaidPrice()
        }
        invoicing = invoice ? invoice.status : 'à facturer'
      }
      return {
        id: copy.id,
        publicationType: copy.publication.publicationType.name,
        publication: copy.publication.title,
        createdOn: copy.createdOn,
        sentOn: copy.sentOn | null,
        totalPrice: copy.totalPrice,
        unitPrice: copy.unitPrice,
        discount: copy.discount,
        sendingPrice: copy.discount,
        sending: copy.sending ? 'Oui' : '',
        type: copy.deposit ? 'Dépôt' : '',
        quantity: copy.quantity,
        delete: '<i class="fas fa-trash"></i>',
        copy: copy,
        invoice: invoice,
        invoicing: invoicing,
        pay: pay,
      }
    },
    makeSubscriptionItem(subscriptor) {
      let invoice = null
      let pay = 0
      if (subscriptor.sale) {
        invoice = subscriptor.sale.invoice
        if (invoice) {
          pay = invoice.toBePaidPrice()
        }
      }
      return {
        id: subscriptor.id,
        subscriptionType: subscriptor.subscription.publicationType.name,
        fromNumber: subscriptor.subscription.fromNumber,
        toNumber: subscriptor.subscription.toNumber,
        createdOn: subscriptor.createdOn,
        sentOn: subscriptor.sentOn | null,
        totalPrice: subscriptor.totalPrice,
        unitPrice: subscriptor.unitPrice,
        discount: subscriptor.discount,
        sendingPrice: subscriptor.discount,
        sending: subscriptor.sending ? 'Oui' : '',
        type: subscriptor.deposit ? 'Dépôt' : '',
        quantity: subscriptor.quantity,
        delete: '<i class="fas fa-trash"></i>',
        subscriptor: subscriptor,
        invoice: invoice,
        invoicing: invoice ? invoice.status : 'à facturer',
        pay: pay,
      }
    },
    onDeleteCopy(item) {
      this.selectedCopy = item.copy
      this.$bvModal.show('bv-confirm-modal:delete-entity-publication-copy')
    },
    async onConfirmDelete(event) {
      const copy = event.object
      try {
        const url = '/publications/api/delete-publication-copy/' + copy.id + '/'
        const backendApi = new BackendApi('delete', url)
        await backendApi.callApi()
        await this.addSuccess('L\'exemplaire a été supprimé')
        await this.loadEntityItems()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    payInvoice(item) {
      this.paymentInvoice = item.invoice
      this.$nextTick(
        () => {
          this.$bvModal.show('pay-entity-copy-modal')
        }
      )
    },
    showAddCopy() {
      this.$bvModal.show('bv-add-entity-publication-copy')
    },
    showAddSubscriptor() {
      this.$bvModal.show('bv-add-entity-subscriptor')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
