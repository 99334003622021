<template>
  <div v-if="canView">
    <div>
      <div class="field-group-block">
        <div class="field-group-header">
          <b-row>
            <b-col cols="8">
              <b>Fiche de liaison {{ individual.firstAndLastName() }}</b>
            </b-col>
            <b-col cols="4" class="text-right">
              <b-dropdown variant="secondary" class="extra-links" size="sm">
                <template #button-content>
                  <i class="fa fa-file-pdf"></i> Générer la fiche
                </template>
                <b-dropdown-item
                  v-for="schoolYear in schoolYears" :key="schoolYear.id"
                  @click.prevent="onGenerateDocument(schoolYear)"
                  variant="secondary"
                  class="dropdown-item-small"
                >
                  {{ schoolYear.name }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </div>
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div v-if="!isLoading(loadingName)">
          <div class="field-line warning-line" v-if="isDocumentUpToDate">
            <b-row>
              <b-col cols="8"><i class="fa fa-warning"></i> La fiche liaison n'est pas à jour</b-col>
            </b-row>
          </div>
          <div class="field-line valid-line" v-else>
            <i class="fa fa-check"></i> La fiche liaison est à jour
          </div>
          <div class="field-line" v-for="document of documents" :key="document.schoolYear.id">
            <b-row>
              <b-col cols="6">
                <div>{{ document.schoolYear.name }}</div>
                <div v-if="document.uploadOn" class="small2">
                  mis à jour le
                  {{ document.uploadOn | dateToString('DD/MM/YYYY HH:mm') }}
                </div>
                <div v-if="document.saveOn" class="small2">
                  modifié le
                  {{ document.saveOn | dateToString('DD/MM/YYYY HH:mm') }}
                </div>
              </b-col>
              <b-col cols="3" class="small2">
                <upload-individual-link
                  :member="member"
                  :school-year="document.schoolYear"
                  :all-docs="documents"
                  @uploaded="onDocumentUploaded"
                >
                </upload-individual-link>
              </b-col>
              <b-col cols="3" class="text-right small">
                <span v-if="document.id">
                  <a href @click="downloadDocument($event, document)">
                    <i class="fa fa-download"></i> Télécharger
                  </a>
                </span>
                <span v-else class="empty-text">
                  Pas de fiche liaison
                </span>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import { makeIndividualLinkDocument } from '@/types/document'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi, openDocument } from '@/utils/http'
import LoadingGif from '@/components/Controls/LoadingGif'
import UploadIndividualLink from '@/components/Documents/UploadIndividualLink'

export default {
  name: 'individual-link-document',
  components: { UploadIndividualLink, LoadingGif, },
  mixins: [BackendMixin],
  props: {
    member: Object,
  },
  data() {
    return {
      documents: [],
      loadingName: 'individual-link-documents',
      isDocumentUpToDate: false,
    }
  },
  created() {
    this.loadDocuments()
  },
  computed: {
    schoolYears() {
      return this.documents.map(elt => elt.schoolYear)
    },
    individual() {
      return this.member.individual
    },
    memberUpdatedOn() {
      return this.member.individual.updatedOn
    },
    canView() {
      return this.hasPerm('documents.view_individuallinkdocument')
    },
  },
  watch: {
    member: function(member) {
      this.loadDocuments()
    },
    memberUpdatedOn: function(memberUpdatedOn) {
      this.checkIfDocumentsAreUpToDate(memberUpdatedOn)
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onGenerateDocument(schoolYear) {
      if (this.member.id) {
        const docUrl = '/documents/individual-link-document/<key>/pdf/'
        const docSlug = '' + this.member.id + '-' + schoolYear.id
        try {
          await openDocument(docUrl, docSlug)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadDocuments() {
      if (this.member.id && this.canView) {
        this.startLoading(this.loadingName)
        const backendApi = new BackendApi(
          'get', '/documents/api/individual-link-documents/' + this.member.id + '/'
        )
        try {
          const resp = await backendApi.callApi()
          this.documents = resp.data.map(elt => makeIndividualLinkDocument(elt))
          this.checkIfDocumentsAreUpToDate(this.memberUpdatedOn)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    onDocumentUploaded(documentId) {
      this.loadDocuments()
    },
    async downloadDocument(event, doc) {
      event.preventDefault()
      if (this.member.id) {
        const docUrl = '/documents/download-individual-link-document/<key>/'
        const docSlug = '' + doc.id
        try {
          await openDocument(docUrl, docSlug)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    checkIfDocumentsAreUpToDate(memberUpdatedOn) {
      let lastUpdate = null
      for (const doc of this.documents) {
        if (doc.saveOn) {
          if (lastUpdate === null) {
            lastUpdate = doc.saveOn
          } else {
            if (doc.saveOn > lastUpdate) {
              lastUpdate = doc.saveOn
            }
          }
        }
      }
      this.isDocumentUpToDate = (
        (lastUpdate === null || moment(lastUpdate) < moment(memberUpdatedOn))
      )
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
