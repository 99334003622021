<template>
  <span class="adhesion-cancel-button" v-if="hasPerm('adhesions.delete_adhesion')">
    <a href @click.prevent="showModal" class="btn btn-xs btn-secondary">
      <i class="fas fa-close"></i>
      Annuler
    </a>
    <b-modal
      dialog-class="modal-lg"
      :id="getId()"
      cancel-title="Non"
      @ok.prevent="onSave"
      ok-variant="primary"
      ok-title="Oui, Confirmer l'annulation"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-close"></i> Annuler une adhésion</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div class="confirm-text">
        Souhaitez-vous annuler l'adhesion {{ adhesion.schoolYear.name }}
        <span v-if="adhesion.adhesionType.applyToIndividual()">
          de {{ adhesion.individual.firstAndLastName() }}?
        </span>
        <span v-else>
          de la famille {{ adhesion.entity.name }}?
        </span>
      </div>
      <b-row>
        <b-col>
          <b-row class="">
            <b-col>
              <b-form-group
                id="refund-group"
                label-for="refund"
                description="En cochant la case, la vente associée sera annulée ou remboursée si déjà facturée"
              >
              <b-checkbox
                  id="refund"
                  v-model="refund"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="adhesion.invoice === null"
                >
                  Rembourser l'adhésion
                </b-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'

export default {
  name: 'adhesion-cancel-button',
  mixins: [BackendMixin],
  props: {
    adhesion: Object,
    entity: Object,
  },
  components: {
  },
  data() {
    return {
      randomId: getRandomId(),
      refund: true,
      errorText: '',
    }
  },
  computed: {
  },
  watch: {
    adhesion: function(newValue, oldValue) {},
    entity: function(newValue, oldValue) {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-adhesion-cancel-button' + this.randomId
    },
    async onSave() {
      this.errorText = ''
      try {
        const url = '/api/entity/' + this.entity.id + '/adhesions/' + this.adhesion.id + '/cancel/'
        const backendApi = new BackendApi('patch', url)
        let data = {
          refund: this.refund,
        }
        await backendApi.callApi(data)
        this.$bvModal.hide(this.getId())
        if (this.refund) {
          await this.addSuccess('L\'adhésion a été annulée et remboursée')
        } else {
          await this.addSuccess('L\'adhésion a été annulée sans remboursement')
        }
        this.$emit('done')
      } catch (err) {
        this.errorText = this.getErrorText(err)
        await this.addError(this.getErrorText(err))
      }
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
  .confirm-text {
    font-weight: bold;
  }
</style>
