<template>
  <div>
    <div
      :style="getStyle(maxInscriptions, minLimit)"
      @click.prevent="showDetail = !showDetail"
      class="clickable"
    >
      {{ maxInscriptions }} / {{ minLimit }}
    </div>
    <div v-if="showDetail">
      <div
        v-for="limit of limits"
        :key="limit.id"
        :style="getStyle(limit.inscriptions, limit.limit)"
        style="font-size: 10px"
      >
        {{ displayHourMin(limit.startTime) }} - {{ displayHourMin(limit.endTime) }}:
        {{ limit.inscriptions }} / {{ limit.limit }}
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { displayHourMin } from '@/utils/time'

export default {
  name: 'NurseryLimitsIndicator',
  components: {},
  props: {
    limits: Array,
  },
  data() {
    return {
      maxInscriptions: 0,
      minLimit: 0,
      isAvailable: false,
      minDelta: 0,
      showDetail: false,
    }
  },
  watch: {
    limits: function() {
      this.init()
    },
  },
  computed: {
  },
  methods: {
    displayHourMin,
    init() {
      let notSet = true
      if (this.limits) {
        for (const limit of this.limits) {
          let isSet = false
          const delta = limit.limit - limit.inscriptions
          if (notSet) {
            this.minDelta = delta
            notSet = false
            isSet = true
          } else if (this.delta < this.minDelta) {
            this.minDelta = delta
            isSet = true
          }
          if (isSet) {
            this.maxInscriptions = limit.inscriptions
            this.minLimit = limit.limit
          }
        }
      }
    },
    getStyle(ins, lim) {
      const delta = lim - ins
      let color = '#5bc45b'
      if (delta <= 0) {
        color = '#f17373'
      } else if (delta <= 2) {
        color = '#efcb64'
      }
      return {
        background: color,
        padding: '2px',
        'text-align': 'right',
        'border-radius': '2px',
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style lang="less">
</style>
