<template>
  <div v-if="canView">
    <div class="field-group-block info-block-vaccines" v-if="vaccines.length">
      <div class="field-group-header" v-if="!showForm">
        <b-row>
          <b-col cols="9">
            <b>Vaccinations. <span v-if="birthYear">Enfant né en {{ birthYear }}</span></b>
          </b-col>
          <b-col cols="3" class="text-right">
            <a
              class="btn btn-primary btn-sm"
              href
              v-if="hasAllPerms(['people.change_individual', 'vaccines.change_vaccination'])"
              @click="onEdit($event)" :class="editDisabled ? 'disabled' : ''"
            >
              <i class="fa fa-edit"></i> Modifier
            </a>
          </b-col>
        </b-row>
      </div>
      <div v-if="!showForm">
        <div v-for="vaccine in vaccines" v-bind:key="vaccine.id" class="field-line vaccine-line">
          <div :class="'vaccine-line' + vaccine.id">
            <span class="vaccine-checkbox">
              <i class="fa fa-check-square" v-if=isDone(vaccine)></i>
              <i class="far fa-square" v-else></i>
            </span>
            <b>{{ vaccine.name }}</b>
            <span
              class="mandatory-mark"
              :class="{ 'warning-mark': !isDone(vaccine), }"
              v-if="vaccine.isMandatoryForChild(birthYear)"
            >
              Obligatoire
            </span>
            <span class="optional-mark" v-else>Optionnel</span>

            <span class="vaccine-date" v-if="doneOn(vaccine)">
              {{ doneOn(vaccine) | dateToString('DD/MM/YYYY') }}
            </span>
          </div>
          <div class="vaccine-comments" :class="'comments' + vaccine.id" v-if="getComments(vaccine)">
            {{ getComments(vaccine) }}
          </div>
        </div>
      </div>
      <div v-if="showForm">
        <b-form @submit.prevent="onSave()">
          <div class="field-group-header">
            <b-row>
              <b-col cols="6">
                <b>Vaccinations</b>
              </b-col>
              <b-col cols="6" class="text-right">
                <b-dropdown right variant="secondary" class="check-all-dropdown">
                  <template v-slot:button-content>
                    <i class="fa fa-check"></i> Cocher
                  </template>
                  <b-dropdown-item @click.prevent="checkAll(true, false)">
                    <i class="far fa-check-square"></i> Tous
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="checkAll(true, true)">
                    <i class="fa fa-check-square mandatory-color"></i> Obligatoires
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="checkAll(false, false)">
                    <i class="far fa-square"></i> Aucun
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </div>
          <div v-for="vaccine in vaccines" v-bind:key="vaccine.id" class="field-line vaccine-line">
            <b-row>
              <b-col>
                <b-form-group
                  :id="'vaccine-group-' + vaccine.id"
                  :label-for="'vaccine-' + vaccine.id"
                >
                  <b-form-checkbox
                    :id="'vaccine' + vaccine.id"
                    v-model="vaccinesData[vaccine.id]"
                    :name="'vaccine' + vaccine.id"
                    :value="true"
                    :unchecked-value="false"
                  >
                    {{ vaccine.name }}
                    <span
                      class="mandatory-mark"
                      :class="{ 'warning-mark': !vaccinesData[vaccine.id], }"
                      v-if="vaccine.isMandatoryForChild(birthYear)">Obligatoire
                    </span>
                    <span class="optional-mark" v-else>Optionnel</span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :id="'date-vaccine-' + vaccine.id"
                  label="Date de vaccination"
                  :label-for="'dates' + vaccine.id"
                  v-if="false"
                >
                  <!-- TODO : Rendre configurable -->
                  <b-form-input
                    :id="'date' + vaccine.id"
                    v-model="datesData[vaccine.id]"
                    type="date"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  :id="'comments-vaccine-' + vaccine.id"
                  description="Commentaires"
                  :label-for="'comments' + vaccine.id"
                >
                  <b-form-input
                    :id="'comments' + vaccine.id"
                    v-model="commentsData[vaccine.id]"
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <b-row class="buttons-bar">
            <b-col class="text-left">
            </b-col>
            <b-col class="text-right">
              <a class="btn btn-secondary btn-cancel" href v-on:click="onCancel">Annuler</a>
              <b-button type="submit" variant="primary">Enregistrer</b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import store from '@/store'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import {
  makeVaccinations, VACCINE_APPLY_TO_INDIVIDUALS, VACCINE_APPLY_TO_ADULTS, VACCINE_APPLY_TO_CHILDREN
} from '@/types/vaccines'

export default {
  name: 'vaccines-detail',
  mixins: [BackendMixin],
  props: {
    member: Object,
  },
  data() {
    return {
      vaccinesData: {},
      commentsData: {},
      datesData: {},
      memberVaccinations: null,
    }
  },
  mounted() {
    this.loadValues(this.member)
  },
  computed: {
    vaccines() {
      if (store.getters.vaccines) {
        return store.getters.vaccines.filter(elt => {
          if (elt.applyTo === VACCINE_APPLY_TO_INDIVIDUALS) {
            return true
          } else if (elt.applyTo === VACCINE_APPLY_TO_ADULTS) {
            return !this.isChild
          } else if (elt.applyTo === VACCINE_APPLY_TO_CHILDREN) {
            return this.isChild
          }
          return false
        })
      }
      return null
    },
    birthYear() {
      if (this.member.individual) {
        const birthDate = this.member.individual.birthDate
        if (birthDate) {
          return moment(birthDate).year()
        }
      }
      return 0
    },
    isChild() {
      return this.member.role.isChild
    },
    editDisabled() {
      return store.getters.editMode !== ''
    },
    showForm() {
      return store.getters.editMode === 'individual-vaccinations'
    },
    canView() {
      return this.hasAllPerms(['people.view_individual', 'vaccines.view_vaccination'])
    },
  },
  watch: {
    member: function(newMember, oldMember) {
      this.loadValues(newMember)
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    ...mapMutations(['setVaccinations', 'setEditMode']),
    onEdit(evt, vaccines) {
      evt.preventDefault()
      if (!this.editDisabled) {
        this.vaccinesData = this.initializeVaccinesData(vaccines)
        this.commentsData = this.initializeCommentsData(vaccines)
        this.datesData = this.initializeDatesData(vaccines)
        this.setEditMode('individual-vaccinations')
      }
    },
    onCancel(evt) {
      evt.preventDefault()
      this.setEditMode('')
    },
    doneOn(vaccine) {
      if (this.memberVaccinations) {
        return this.memberVaccinations.doneOn(vaccine)
      }
      return ''
    },
    isDone(vaccine) {
      if (this.memberVaccinations) {
        return this.memberVaccinations.isDone(vaccine)
      }
      return false
    },
    getComments(vaccine) {
      if (this.memberVaccinations) {
        return this.memberVaccinations.getComments(vaccine)
      }
      return ''
    },
    initializeVaccinesData() {
      let data = {}
      for (let vaccine of this.vaccines) {
        data[vaccine.id] = this.isDone(vaccine)
      }
      return data
    },
    initializeCommentsData() {
      let data = {}
      for (let vaccine of this.vaccines) {
        data[vaccine.id] = this.getComments(vaccine)
      }
      return data
    },
    initializeDatesData() {
      let data = {}
      for (let vaccine of this.vaccines) {
        data[vaccine.id] = this.doneOn(vaccine)
      }
      return data
    },
    async onSave() {
      this.setEditMode('')
      let url = ''
      if (this.member && this.member.id) {
        url = '/api/vaccines/vaccinations/' + this.member.individual.id + '/'
      }
      let postData = []
      for (let vaccine of this.vaccines) {
        postData.push(
          {
            vaccine: vaccine.id,
            done: !!this.vaccinesData[vaccine.id],
            done_on: this.datesData[vaccine.id] || null,
            comments: this.commentsData[vaccine.id] || '',
          }
        )
      }
      if (url) {
        const backendApi = new BackendApi('post', url)
        try {
          let resp = await backendApi.callApi(postData)
          this.memberVaccinations = makeVaccinations(resp.data)
          await this.addSuccess('Les données de "Vaccination" ont été enregistrées')
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      } else {
        await this.addError('Impossible de charger les données de vaccination')
      }
    },
    async loadValues(member) {
      if (this.canView) {
        let url = ''
        if (member && member.id) {
          url = '/api/vaccines/vaccinations/' + member.individual.id + '/'
        }
        if (url) {
          const backendApi = new BackendApi('get', url)
          try {
            let resp = await backendApi.callApi()
            this.memberVaccinations = makeVaccinations(resp.data)
          } catch (err) {
            await this.addError(this.getErrorText(err))
          }
        } else {
          await this.addError('Impossible de charger les données de vaccination')
        }
      }
    },
    checkAll(check, onlyMandatory) {
      for (let vaccine of this.vaccines) {
        if (!onlyMandatory || vaccine.isMandatoryForChild(this.birthYear)) {
          this.vaccinesData[vaccine.id] = check
        } else if (onlyMandatory && !vaccine.isMandatoryForChild(this.birthYear)) {
          this.vaccinesData[vaccine.id] = false
        }
      }
      this.vaccinesData = { ...this.vaccinesData, }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.vaccine-checkbox {
  margin-right: 5px;
}
.mandatory-color {
  color: #f3d250;
  background: #000;
}
.mandatory-mark {
  padding: 2px 8px;
  border-radius: 4px;
  margin: 0 5px;
  background: #50c2f3;
  color: #000;
}
.warning-mark {
  background: #ee5f5b;
}
.optional-mark {
  padding: 2px 8px;
  border-radius: 4px;
  margin: 0 5px;
  background: #ececec;
  color: #000;
}
.vaccine-comments {
  margin-top: 2px;
  background: #ccc;
  padding: 5px;
}
.vaccine-line {
  padding: 5px;
}

</style>
