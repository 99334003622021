<template>
  <div class="seances-filter">
    <b-form>
      <div class="field-line">
        <b-row>
          <b-col>
            <div v-if="showFastFilter">
              <b-input
                type="text"
                v-model="textFilter"
                placeholder="Filtrer les périodes"
                class="elt-search-select"
              >
              </b-input>
            </div>
            <div v-else>
              <b-row class="">
                <b-col v-for="filterElt of filters" :key="filterElt.id">
                  <b-form-select
                    :id="'filter-' + filterElt.id"
                    v-model="selectedFilters[filterElt.id]"
                    class="small-text"
                    @change="onFilterChanged()"
                    >
                    <b-form-select-option
                      :value="item"
                      v-for="item in filterElt.items"
                      v-bind:key="item.id"
                    >
                      {{ item.name }}
                    </b-form-select-option>
                  </b-form-select>
                  <div class="filter-description">
                    {{ filterElt.name }}
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="3" class="text-right">
            <div class="filter-buttons">
              <a
                class="btn btn-sm"
                :class="zeroFilter ? 'btn-warning' : 'btn-secondary'"
                @click.prevent="zeroFilter = !zeroFilter"
              >
                <i class="fa fa-0"></i>
              </a>
              <a
                class="btn btn-secondary btn-sm"
                :class="hasFilter ? '' : 'disabled'"
                href @click.prevent="onClear"
              >
                <i class="fa fa-broom"></i>
              </a>
              <a class="btn btn-sm" :class="hasFilter ? 'btn-danger' : 'btn-primary'" href @click.prevent="showAll = !showAll">
                <i class="fa fa-filter"></i>
              </a>
              <a class="btn btn-secondary btn-sm" href @click.prevent="showFastFilter = !showFastFilter">
                <i class="fa fa-rotate"></i>
              </a>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="showAll">
        <div class="field-line">
          <b-row>
            <b-col>
              <div class="top-right-holder">
                <div class="top-right">
                  <a href class="btn btn-sm btn-secondary" @click.prevent="selectAllDays">Tous</a>
                  <a href class="btn btn-sm btn-secondary" @click.prevent="unselectAllDays">Aucun</a>
                </div>
                <b-form-group
                  id="filter-group-weekday"
                  label="Jours de la semaine"
                  label-for="filter-weekday"
                >
                  <div class="inline space-right" v-for="weekday in daysOfTheWeek" :key="weekday.id">
                    <b-checkbox
                      v-model="selectedWeekDays[weekday.id]"
                      :name="weekday.name"
                      :value="true"
                      :unchecked-value="false"
                    >
                      {{ weekday.name }}
                    </b-checkbox>
                  </div>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="3" class="text-right">
              <b-form-group>
                <br />
                <b-checkbox
                  v-model="evenWeeks"
                  name="evenWeeks"
                >
                  Semaines Impaires
                </b-checkbox>
                <b-checkbox
                  v-model="oddWeeks"
                  name="oddWeeks"
                >
                  Semaines paires
                </b-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="field-line">
          <b-row>
            <b-col>
              <div class="top-right-holder">
                <div class="top-right">
                  <a href class="btn btn-sm btn-secondary" @click.prevent="selectAllTimes">Tous</a>
                  <a href class="btn btn-sm btn-secondary" @click.prevent="unselectAllTimes">Aucun</a>
                </div>
                <b-form-group
                  id="filter-group-day"
                  label="Moments de la journée"
                  label-for="filter-day"
                >
                  <div class="inline space-right" v-for="dayTime in timesOfTheDay" :key="dayTime.id">
                    <b-checkbox
                      v-model="selectedDayTimes[dayTime.id]"
                      :name="dayTime.name"
                      :value="true"
                      :unchecked-value="false"
                    >{{ dayTime.name }}</b-checkbox>
                  </div>
                </b-form-group>
              </div>
            </b-col>
            <b-col>
              <div class="top-right-holder border-left">
                <div class="top-right">
                  <a href class="btn btn-sm btn-secondary" @click.prevent="selectFuture">À venir</a>
                  <a href class="btn btn-sm btn-secondary" @click.prevent="selectThisWeek">Cette semaine</a>
                  <a href class="btn btn-sm btn-secondary" @click.prevent="selectThisMonth">Ce mois</a>
                </div>
                <b-form-group
                  id="filter-group-date-to-to"
                  label="Date à date"
                  label-for="filter-date-to-date"
                >
                  <b-row>
                    <b-col>
                      <b>Du</b><br />
                      <b-input
                        v-model="dateFrom"
                        type="date"
                        @change="onFilterChanged()"
                      ></b-input>
                    </b-col>
                    <b-col>
                      <b>Au</b><br />
                      <b-input
                        v-model="dateTo"
                        type="date"
                        @change="onFilterChanged()"
                      ></b-input>
                    </b-col>
                  </b-row>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'

export default {
  name: 'SeancesFilter',
  mixins: [BackendMixin],
  props: {
    filters: Array,
    weekDays: Array,
    dayTimes: Array,
  },
  data() {
    return {
      selectedFilters: {},
      selectedWeekDays: [],
      selectedDayTimes: [],
      dateFrom: null,
      dateTo: null,
      showAll: false,
      showFastFilter: true,
      textFilter: '',
      zeroFilter: false,
      evenWeeks: true,
      oddWeeks: true,
    }
  },
  created() {
    this.selectedWeekDays = this.weekDays || [ ...Array(7).keys() ].map(elt => true)
    this.selectedDayTimes = this.dayTimes || [ ...Array(4).keys() ].map(elt => true)
  },
  computed: {
    hasFilter() {
      return (
        this.dateFrom || this.dateTo || !this.allWeekDaysActive || !this.allDayTimesActive ||
        !this.oddWeeks || !this.evenWeeks
      )
    },
    daysOfTheWeek() {
      return [ ...Array(7).keys() ].map(
        index => {
          return { id: index, name: moment.weekdays(true, index), }
        }
      )
    },
    timesOfTheDay() {
      const daytimelabels = store.getters.daytimeLabels
      const timesOfTheDay = []
      const defaults = ['Matin', 'Repas', 'Après-Midi', 'Soirée']
      for (let index = 0; index < defaults.length; index++) {
        let label = defaults[index]
        if (daytimelabels && daytimelabels.length > index) {
          if (daytimelabels[index]) {
            label = daytimelabels[index]
          }
        }
        timesOfTheDay.push({ id: index, name: label, })
      }
      return timesOfTheDay
    },
    allWeekDaysActive() {
      return this.selectedWeekDays.filter(elt => !elt).length === 0
    },
    allDayTimesActive() {
      return this.selectedDayTimes.filter(elt => !elt).length === 0
    },
  },
  watch: {
    selectedWeekDays: function() {
      this.onFilterChanged()
    },
    selectedDayTimes: function() {
      this.onFilterChanged()
    },
    textFilter: function() {
      this.onFilterChanged()
    },
    zeroFilter: function() {
      this.onFilterChanged()
    },
    oddWeeks: function() {
      this.onFilterChanged()
    },
    evenWeeks: function() {
      this.onFilterChanged()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    onCancel(evt) {
      evt.preventDefault()
      this.$emit('cancel', {})
    },
    onClear(evt) {
      evt.preventDefault()
      this.selectedFilters = {}
      this.selectAllDays()
      this.selectAllTimes()
      this.dateFrom = null
      this.dateTo = null
      this.oddWeeks = true
      this.evenWeeks = true
    },
    async onFilterChanged() {
      this.selectedFilters['text'] = this.textFilter
      this.selectedFilters['zeroFilter'] = this.zeroFilter
      if (!this.allWeekDaysActive) {
        this.selectedFilters['weekDays'] = this.selectedWeekDays
      } else {
        this.selectedFilters['weekDays'] = null
      }
      if (!this.allDayTimesActive) {
        this.selectedFilters['dayTimes'] = this.selectedDayTimes
      } else {
        this.selectedFilters['dayTimes'] = null
      }
      this.selectedFilters['dateFrom'] = this.dateFrom
      this.selectedFilters['dateTo'] = this.dateTo
      this.selectedFilters['noOddWeeks'] = !this.oddWeeks
      this.selectedFilters['noEvenWeeks'] = !this.evenWeeks
      this.selectedFilters = { ...this.selectedFilters, } // force maj
      this.$emit('filterChanged', this.selectedFilters)
    },
    selectAllDays() {
      let values = this.selectedWeekDays.filter(elt => true)
      for (let index = 0; index < values.length; index++) {
        values[index] = true
      }
      this.selectedWeekDays = values
    },
    unselectAllDays() {
      let values = this.selectedWeekDays.filter(elt => true)
      for (let index = 0; index < values.length; index++) {
        values[index] = false
      }
      this.selectedWeekDays = values
    },
    selectAllTimes() {
      let values = this.selectedDayTimes.filter(elt => true)
      for (let index = 0; index < values.length; index++) {
        values[index] = true
      }
      this.selectedDayTimes = values
    },
    unselectAllTimes() {
      let values = this.selectedDayTimes.filter(elt => true)
      for (let index = 0; index < values.length; index++) {
        values[index] = false
      }
      this.selectedDayTimes = values
    },
    selectFuture() {
      let now = moment()
      this.dateFrom = now.format('YYYY-MM-DD')
      this.onFilterChanged()
    },
    selectThisWeek() {
      let now = moment()
      this.dateFrom = now.clone().weekday(0).format('YYYY-MM-DD')
      this.dateTo = now.clone().weekday(6).format('YYYY-MM-DD')
      this.onFilterChanged()
    },
    selectThisMonth() {
      let now = moment()
      this.dateFrom = now.startOf('month').format('YYYY-MM-DD')
      this.dateTo = now.endOf('month').format('YYYY-MM-DD')
      this.onFilterChanged()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .seances-filter {
    background: #444;
    color: #fff;
  }
  .small-text {
    font-size: 10px;
    padding: 2px;
  }
  .inline {
    display: inline-block;
  }
  .space-right {
    margin-right: 25px;
  }
  .space-right .custom-control {
  }
  .top-right-holder {
    position: relative;
  }
  .top-right {
    position: absolute;
    right: 5px;
    top: 0;
  }
  .top-right a {
    margin-left: 5px;
  }
  .border-left {
    color: #f0f0f0;
    padding-left: 5px;
  }
  .elt-search-select {
    font-size: 10px;
  }
  .filter-description {
    font-size: 11px;
  }
  .filter-buttons a {
    margin-left: 2px;
    display: inline-block;
    width: 32px;
  }
</style>
