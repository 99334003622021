<template>
  <div v-if="contract && canAdd" class="nursery-action-line">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="!isFormVisible" class="text-right">
      <a href @click.prevent="showForm()" class="btn btn-primary btn-sm">
        <i class="fa fa-dates"></i> Inscrire
      </a>
    </div>
    <div v-else-if="!showPreview">
      <b-form @submit.prevent="onPreview">
        <b-row>
          <b-col>
            <b-form-group label="Enfant" label-for="selectedChild">
              <b-input type="text" disabled id="selectedChild" :value="contract.individual.firstAndLastName()">
              </b-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Du" label-for="startDate" :description="startDateShort">
              <b-form-input v-model="startDate" id="startDate" type="date" :disabled="!canChangeStartDate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="au" label-for="endDate" :description="endDateShort">
              <b-form-input v-model="endDate" id="endDate" type="date" :disabled="!canChangeDate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Type de contrat" label-for="contractType">
              <b-input disabled id="contractType" :value="contract.getContractTypeLabel()">
              </b-input>
              <div>
                <b-form-checkbox id="forceChangeDate" v-model="forceChangeDate" class="small-text">
                  Modification exceptionnelle
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <nursery-inscription-grid
          @change="onGridChanged"
          :contract="contract"
          :force="forceChangeDate"
        ></nursery-inscription-grid>
        <b-row>
          <b-col>
            <b-form-group label="Adaptation" label-for="selectedChild">
              <b-form-checkbox id="adaptation" v-model="adaptation">
                Périodes d'adaptation
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Avec parents jusqu'au" label-for="adaptation1">
              <b-form-input
                v-model="adaptationDate1"
                id="adaptation1"
                type="date"
                :disabled="!adaptation"
                :min="contract.startDate"
                :max="contract.endDate"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Sans parents jusqu'au" label-for="adaptation2">
              <b-form-input
                v-model="adaptationDate2"
                id="adaptation2"
                type="date"
                :disabled="!adaptation"
                :min="adaptationDate1 || contract.startDate"
                :max="contract.endDate"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="buttons-bar">
          <b-col class="text-left">
          </b-col>
          <b-col class="text-right">
            <a class="btn btn-secondary btn-cancel" href @click.prevent="onCancel">Annuler</a>
            <b-button type="submit" variant="primary" :disabled="!isValid()">Prévisualiser</b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div v-else>
      <b>
        <counter-label :counter="selectedInscriptionsCount" label="inscription"></counter-label>
      </b>
      <table class="table table-striped">
        <tr
          v-for="ins of inscriptions"
          :key="ins.id"
          class="field-line small-text"
          :class="{'is-ignored': isIgnored(ins), 'exists': ins.id > 0}"
        >
          <td>
            {{ ins.date | dateToString('ddd D MMM YYYY') }}
            <div v-if="offDayPeriod(ins)">
              <span class="badge badge-warning">fermeture {{ offDayPeriod(ins) }}</span>
            </div>
          </td>
          <td>
            {{ ins.getAdaptationLabel() }}
          </td>
          <td>
            <span v-if="ins.adaptation">
              <time-picker-input
                small v-model="ins.arrivalAt" :id="'arrival' + ins.id"
                @change="setLunch(ins)" :disabled="isIgnored(ins)"
              ></time-picker-input>
            </span>
            <span v-else>
              {{ displayHourMin(ins.getArrival()) }}
            </span>
          </td>
          <td>
            <span v-if="ins.adaptation">
              <time-picker-input
                small v-model="ins.leavingAt" :id="'leaving' + ins.id"
                @change="setLunch(ins)" :disabled="isIgnored(ins)"
              ></time-picker-input>
            </span>
            <span v-else>
              {{ displayHourMin(ins.getLeaving()) }}
            </span>
          </td>
          <td>
            <span v-if="ins.adaptation">
              <b-form-checkbox v-model="ins.lunch" :id="'lunch' + ins.id">Repas</b-form-checkbox>
            </span>
            <span v-else>
              <span v-if="ins.lunch">
                <i class="fa fa-cutlery"></i> Repas
              </span>
            </span>
          </td>
          <td colspan="3"  v-if="ins.id > 0">
            L'inscription sera modifiée
          </td>
          <td v-if="ins.id < 0" style="width: 130px;">
            <nursery-limits-indicator :limits="getLimits(ins)">
            </nursery-limits-indicator>
          </td>
          <td v-if="ins.id < 0">
            {{ ins.getExpectedPrice() | currency }}
          </td>
          <td  v-if="ins.id < 0" class="text-right">
            <a href @click.prevent="toggleIgnoreDate(ins)" title="ignorer" v-b-tooltip v-if="!isIgnored(ins)">
              <i class="fa fa-trash"></i>
            </a>
            <a href @click.prevent="toggleIgnoreDate(ins)" title="inclure" v-b-tooltip v-else>
              <i class="fa fa-plus"></i>
            </a>
          </td>
        </tr>
      </table>
      <div v-if="cancellations.length">
        <b>
          <counter-label :counter="cancellations.length" label="annulation"></counter-label>
        </b>
        <table class="table table-striped">
          <tr
            v-for="ins of cancellations"
            :key="ins.id"
            class="field-line small-text"
            :class="{ 'exists': isCancellationIgnored(ins), }"
          >
            <td>
              {{ ins.date | dateToString('ddd D MMM YYYY') }}
              <div v-if="offDayPeriod(ins)" class="badge badge-warning">
                <span class="badge badge-warning">fermeture {{ offDayPeriod(ins) }}</span>
              </div>
            </td>
            <td>
              {{ ins.getAdaptationLabel() }}
            </td>
            <td>
              {{ displayHourMin(ins.getArrival()) }}
            </td>
            <td>
              {{ displayHourMin(ins.getLeaving()) }}
            </td>
            <td>
              <span v-if="ins.lunch">
                <i class="fa fa-cutlery"></i> Repas
              </span>
            </td>
            <td colspan="3"  v-if="ins.id > 0">
              <span v-if="isCancellationIgnored(ins)">L'inscription sera conservée</span>
              <span v-else>L'inscription sera annulée</span>
            </td>
            <td v-if="ins.id < 0">
              {{ ins.getExpectedPrice() | currency }}
            </td>
            <td class="text-right">
              <a
                href @click.prevent="toggleCancellation(ins)" title="ignorer" v-b-tooltip
                v-if="!isCancellationIgnored(ins)"
              >
                <i class="fa fa-trash"></i>
              </a>
              <a href @click.prevent="toggleCancellation(ins)" title="inclure" v-b-tooltip v-else>
                <i class="fa fa-plus"></i>
              </a>
            </td>
          </tr>
        </table>
      </div>
      <b-row class="buttons-bar">
        <b-col class="text-left">
          <b> Total: {{ totalPrice | currency }}</b>
        </b-col>
        <b-col class="text-right">
          <a class="btn btn-secondary btn-cancel" href @click.prevent="onCancel">Annuler</a>
          <a
            href
            class="btn btn-primary"
            @click.prevent="onConfirm()"
            :class="{ disabled: !isConfirmValid() }"
          >Confirmer</a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import TimePickerInput from '@/components/Controls/TimePickerInput.vue'
import NurseryInscriptionGrid from '@/components/Entity/NurseryInscriptionGrid.vue'
import NurseryLimitsIndicator from '@/components/Nursery/NurseryLimitIndicator.vue'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import {
  NurseryContractType, makeNurseryInscription, makeNurseryLimitEx, NurseryAdaptation, makeNurseryOffDay
} from '@/types/nursery'
import { displayHourMin } from '@/utils/time'
import { sum } from '@/utils/math'
import { hasLunch } from '@/utils/nursery'

export default {
  name: 'entity-create-nursery-inscriptions',
  components: {
    TimePickerInput,
    NurseryInscriptionGrid,
    NurseryLimitsIndicator,
    CounterLabel,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    contract: Object,
  },
  data() {
    return {
      loadingName: 'entity-nursery-create-inscriptions',
      inscriptions: [],
      cancellations: [],
      keepCancellations: [],
      selectedChild: null,
      startDate: '',
      endDate: '',
      isFormVisible: false,
      showPreview: false,
      ignoreDates: [],
      limits: new Map(),
      isGridValid: false,
      weeksCycle: 0,
      holidaysCycle: 0,
      days: [],
      adaptation: false,
      adaptationDate1: null,
      adaptationDate2: null,
      startsOn: null,
      forceChangeDate: false,
      offDays: new Map(),
    }
  },
  computed: {
    entity() {
      return this.contract.entity
    },
    canAdd() {
      return (
        this.hasPerm('nursery.add_nurseryinscription') &&
        !this.contract.cancelledOn
      )
    },
    individuals() {
      return this.entity.getChildrenIndividuals()
    },
    totalPrice() {
      return sum(
        this.inscriptions.filter(
          ins => !this.isIgnored(ins) && ins.id < 0
        ).map(
          elt => elt.getExpectedPrice()
        )
      )
    },
    selectedInscriptionsCount() {
      return this.inscriptions.filter(
        ins => !this.isIgnored(ins) // && ins.id < 0
      ).length
    },
    canChangeDate() {
      return this.forceChangeDate || this.contract.contractType !== NurseryContractType.Regular
    },
    canChangeStartDate() {
      return this.canChangeDate || (this.contract.modifiedOn !== null)
    },
    startDateShort() {
      return dateToString(this.startDate, 'ddd ll')
    },
    endDateShort() {
      return dateToString(this.endDate, 'ddd ll')
    },
  },
  watch: {
    entity: function() { this.onCreated() },
    adaptation: function() {
      if (this.adaptation) {
        this.adaptationDate1 = moment(this.contract.startDate).add('14', 'days').format('YYYY-MM-DD')
        this.adaptationDate2 = moment(this.contract.startDate).add('28', 'days').format('YYYY-MM-DD')
      } else {
        this.adaptationDate1 = null
        this.adaptationDate2 = null
      }
    },
    forceChangeDate: function() {
      if (this.forceChangeDate) {
        this.startDate = null
        this.endDate = null
      } else {
        this.startDate = this.contract.startDate
        this.endDate = this.contract.endDate
      }
    },
  },
  created() {
    this.onCreated()
  },
  methods: {
    displayHourMin,
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading', 'setEditMode']),
    getLimits(ins) {
      return this.limits.get(ins.id)
    },
    offDayPeriod(elt) {
      const day = dateToString(elt.date, 'YYYY-MM-DD')
      if (this.offDays.has(day)) {
        return this.offDays.get(day).getOffPeriod()
      }
      return ''
    },
    async onCreated() {
      // this.days = this.getDays()
      this.forceChangeDate = false
    },
    setLunch(ins) {
      // Vérifie la présence à l'heure du repas et coche la case repas si besoin
      ins.lunch = hasLunch(ins.arrivalAt, ins.leavingAt, false, '', '')
    },
    showForm() {
      this.isFormVisible = true
      if (this.contract.modifiedOn) {
        this.startDate = dateToString(this.contract.modifiedOn, 'YYYY-MM-DD')
      } else {
        this.startDate = this.contract.startDate
      }
      this.forceChangeDate = false
      this.endDate = this.contract.endDate
      for (const item of this.contract.items) {
        if (item.day < this.days.length) {
          this.days[item.day].selected = true
          this.days[item.day].arrivalAt = item.arrivalAt
          this.days[item.day].leavingAt = item.leavingAt
          this.days[item.day].lunch = item.lunch
        }
      }
    },
    async checkLimits() {
      if (this.isValid()) {
        let url = '/nursery/api/check-limits/'
        const backendApi = new BackendApi('post', url)
        const items = []
        for (const ins of this.inscriptions.filter(elt => elt.id < 0)) {
          items.push(
            {
              id: ins.id,
              day: dateToString(ins.date, 'YYYY-MM-DD'),
              arrival_at: ins.arrivalAt,
              leaving_at: ins.leavingAt,
            }
          )
        }
        const data = {
          nursery: this.contract.nursery.id,
          items: items,
        }
        try {
          const resp = await backendApi.callApi(data)
          const limits = new Map()
          for (const elt of resp.data) {
            limits.set(elt.id, elt.limits.map(sub => makeNurseryLimitEx(sub)))
          }
          this.limits = limits
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async onCreateInscriptions(preview, ignoreDates) {
      if (this.isValid()) {
        let url = '/nursery/api/create-inscriptions/' + this.entity.id + '/'
        const backendApi = new BackendApi('post', url)
        const items = []
        for (const day of this.days.filter(elt => elt.selected)) {
          items.push(
            {
              day: day.dayId,
              arrival_at: day.arrivalAt,
              leaving_at: day.leavingAt,
              lunch: day.lunch,
              track: 1,
              week_index: day.weekIndex(),
              holidays: day.holidays(),
            }
          )
          if (day.tracks) {
            items.push(
              {
                day: day.dayId,
                arrival_at: day.arrivalAt2,
                leaving_at: day.leavingAt2,
                lunch: false,
                track: 2,
                week_index: day.weekIndex(),
                holidays: day.holidays(),
              }
            )
          }
        }
        const data = {
          preview: preview,
          individual: this.contract.individual.id,
          nursery: null,
          contract: this.contract.id,
          start_date: this.startDate,
          end_date: this.endDate,
          weeks_cycle: this.weeksCycle,
          holidays_cycle: this.holidaysCycle,
          items: items,
          ignores: ignoreDates.map(
            elt => {
              return {
                date: elt,
              }
            }
          ),
          custom_inscriptions: this.inscriptions.filter(
            ins => ins.adaptation && !this.isIgnored(ins)
          ).map(
            elt => {
              return {
                date: elt.date,
                arrival_at: elt.arrivalAt,
                leaving_at: elt.leavingAt,
                track: elt.track,
                lunch: elt.lunch,
              }
            }
          ),
        }
        if (preview) {
          data.adaptation_date1 = this.adaptation ? this.adaptationDate1 : null
          data.adaptation_date2 = this.adaptation ? this.adaptationDate2 : null
        } else {
          data.cancellations = this.cancellations.filter(
            ins => !this.isCancellationIgnored(ins)
          ).map(elt => elt.id)
          data.adaptations1 = this.inscriptions.filter(
            ins => ins.adaptation === NurseryAdaptation.WithParents
          ).map(ins => dateToString(ins.date, 'YYYY-MM-DD'))
          data.adaptations2 = this.inscriptions.filter(
            ins => ins.adaptation === NurseryAdaptation.WithoutParents
          ).map(ins => dateToString(ins.date, 'YYYY-MM-DD'))
        }
        try {
          const resp = await backendApi.callApi(data)
          this.inscriptions = resp.data.inscriptions.map(makeNurseryInscription)
          this.cancellations = resp.data.cancellations.map(makeNurseryInscription)
          const offDays = resp.data.off_days.map(makeNurseryOffDay)
          this.offDays = new Map()
          for (const offDay of offDays) {
            this.offDays.set(dateToString(offDay.date, 'YYYY-MM-DD'), offDay)
          }
          this.keepCancellations = []
          if (preview) {
            this.showPreview = true
          } else {
            this.showPreview = false
            this.isFormVisible = false
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    isValid() {
      let isValid = (
        this.contract && this.startDate && this.endDate
      )
      const selected = this.days.map(elt => elt.selected).filter(elt => elt).length
      isValid = isValid && selected > 0
      if (isValid) {
        isValid = this.isGridValid
      }
      if (isValid && this.adaptation) {
        isValid = this.adaptationDate1 && this.adaptationDate2
      }
      return isValid
    },
    isConfirmValid() {
      return this.isValid() && this.selectedInscriptionsCount !== 0
    },
    async onPreview() {
      this.startLoading(this.loadingName)
      await this.onCreateInscriptions(true, [])
      await this.checkLimits()
      this.endLoading(this.loadingName)
    },
    onCancel() {
      this.showPreview = false
      this.isFormVisible = false
    },
    async onConfirm() {
      this.startLoading(this.loadingName)
      await this.onCreateInscriptions(false, this.ignoreDates)
      this.endLoading(this.loadingName)
      let text = 'Les inscriptions ont été créées'
      if (this.inscriptions.length === 1) {
        text = 'L\'inscription a été créée'
      }
      await this.addSuccess(text)
      this.$emit('done', { inscriptions: this.inscriptions, })
    },
    isIgnored(ins) {
      const key = dateToString(ins.date, 'YYYY-MM-DD')
      return this.ignoreDates.indexOf(key) >= 0
    },
    toggleIgnoreDate(ins) {
      // Attention : TODO Gestion des plages
      const key = dateToString(ins.date, 'YYYY-MM-DD')
      const index = this.ignoreDates.indexOf(key)
      if (index >= 0) {
        this.ignoreDates.splice(index, 1)
      } else {
        this.ignoreDates.push(key)
      }
      this.ignoreDates = [].concat(this.ignoreDates)
    },
    isCancellationIgnored(ins) {
      return this.keepCancellations.indexOf(ins.id) >= 0
    },
    toggleCancellation(ins) {
      const index = this.keepCancellations.indexOf(ins.id)
      if (index >= 0) {
        this.keepCancellations.splice(index, 1)
      } else {
        this.keepCancellations.push(ins.id)
      }
      this.keepCancellations = [].concat(this.keepCancellations)
    },
    onGridChanged(event) {
      this.weeksCycle = event.weeksCycle
      this.holidaysCycle = event.holidaysCycle
      this.isGridValid = event.isValid
      this.days = event.days
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.is-ignored {
  text-decoration: line-through wavy;
  color: #888;
}
.exists {
  color: #aa2c2c;
  font-weight: bold;
}
.nursery-action-line {
  background: #e0e0e0;
  padding: 10px;
}
</style>
