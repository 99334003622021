
export const VACCINE_APPLY_TO_NONE = 0
export const VACCINE_APPLY_TO_INDIVIDUALS = 1
export const VACCINE_APPLY_TO_CHILDREN = 2
export const VACCINE_APPLY_TO_ADULTS = 3

export class Vaccine {
  constructor(
    public id: number,
    public name: string,
    public applyTo: number,
    public isMandatory: boolean,
    public wasMandatoryBefore2018: boolean
  ) {
  }

  public isMandatoryForChild(birthYear: number): boolean {
    if (birthYear && birthYear < 2018) {
      return this.wasMandatoryBefore2018
    } else {
      return this.isMandatory
    }
  }
}

export class Vaccination {
  constructor(
    public vaccine: number,
    public done: boolean,
    public doneOn: Date,
    public comments: string
  ) {
  }
}

export class Vaccinations {
  constructor(
    public vaccinations: Vaccination[]
  ) {
  }

  public isDone(vaccine: Vaccine): boolean {
    for (let vaccination of this.vaccinations) {
      if (vaccination.vaccine === vaccine.id) {
        return vaccination.done
      }
    }
    return false
  }

  public doneOn(vaccine: Vaccine): Date|null {
    for (let vaccination of this.vaccinations) {
      if (vaccination.vaccine === vaccine.id) {
        return vaccination.doneOn
      }
    }
    return null
  }

  public getComments(vaccine: Vaccine): string {
    for (let vaccination of this.vaccinations) {
      if (vaccination.vaccine === vaccine.id) {
        return vaccination.comments
      }
    }
    return ''
  }
}

export function makeVaccine(jsonData: any = null): Vaccine {
  if (jsonData === null) {
    jsonData = {}
  }
  const fields: any[] = jsonData.fields || []
  return new Vaccine(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.apply_to || VACCINE_APPLY_TO_NONE,
    !!jsonData.is_mandatory,
    !!jsonData.was_mandatory_before_2018
  )
}

export function makeVaccinations(jsonData: any = null): Vaccinations {
  if (jsonData === null) {
    jsonData = {}
  }
  let vaccinations: Vaccination[] = []
  for (let elt of jsonData) {
    vaccinations.push(
      new Vaccination(
        elt.vaccine || 0,
        !!elt.done,
        elt.done_on || null,
        elt.comments || ''
      )
    )
  }
  return new Vaccinations(vaccinations)
}
