<template>
  <div v-if="cafApiEnabled && hasPerm('families.change_family')">
    <loading-gif :loading-name="loadingName" short></loading-gif>
    <a href @click.prevent="askConfirm()" title="MAJ des données CAF" v-if="!isLoading(loadingName)">
      <i class="fa fa-download"></i> MAJ CAF
    </a>
    <confirm-modal
      name="caf-load-data"
      title="Téléchargement des données CAF"
      text="Voulez-vous mettre à jour les données CAF"
      @confirmed="loadData()"
    ></confirm-modal>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { BackendApi } from '@/utils/http'

export default {
  name: 'family-load-data-button',
  components: {
    LoadingGif,
    ConfirmModal,
  },
  mixins: [BackendMixin],
  props: {
    familyId: Number,
  },
  data() {
    return {
      loadingName: '',
    }
  },
  computed: {
    cafApiEnabled() {
      const config = store.getters.config
      if (config) {
        return config.cafApiEnabled
      }
      return false
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    askConfirm() {
      this.$bvModal.show('bv-confirm-modal:caf-load-data')
    },
    async loadData() {
      this.startLoading(this.loadingName)
      let url = '/api/families/families/' + this.familyId + '/load_data/'
      let backendApi = new BackendApi('patch', url)
      try {
        let resp = await backendApi.callApi({})
        if (resp.data.success) {
          await this.addSuccess(resp.data.message)
          this.$emit('refresh', { familyData: resp.data.family_data, })
        } else {
          await this.addError(resp.data.message)
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
