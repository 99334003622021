<template>
  <span
    class="youth-extra-sales-button"
    v-if="entity && hasAllPerms(['youth.change_seanceinscription', 'payments.add_sale'])"
  >
    <a
      href @click.prevent="showModal"
      class="btn btn-xs btn-secondary" :class="{ disabled: inscriptions.length === 0, }">
      <i class="fas fa-plus-circle"></i>
      Réduction / Supplément
    </a>
    <b-modal
      dialog-class="modal-lg"
      :id="getId()"
      cancel-title="Annuler"
      :ok-disabled="isDisabled()"
      @ok.prevent="onSave"
      ok-variant="primary"
      ok-title="Ok"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-plus-circle"></i> Réduction / Supplément sur les inscriptions aux séances</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <sales-list :list="sales" no-link></sales-list>
      <div><b>Ajouter une vente pour chaque inscription</b></div>
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <b-form-group
                id="type-group"
                description=""
              >
                <b-select
                  id="type"
                  v-model="choice"
                >
                  <b-select-option v-for="elt of choices" :key="elt.id" :value="elt">
                    {{ elt.name }}
                  </b-select-option>
                </b-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-checkbox
                  id="showPercentage"
                  v-model="showPercentage"
                >
                  En pourcentage
                </b-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="price-group"
                description="Montant en €"
                label-for="price"
                v-if="!showPercentage"
              >
                <decimal-input id="price" v-model="price"></decimal-input>
              </b-form-group>
              <b-form-group
                id="percentage"
                :description="percentageValue"
                label-for="percentage"
                v-if="showPercentage"
              >
                <b-form-input
                  id="percentage"
                  v-model="percentage"
                  type="number"
                  min="0"
                  step="1"
                  max="100"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="comments-group"
                label="Commentaires"
                label-for="comments"
                :description="'Libellé sur la facture.' + (choice ? ('Si vide: ' + choice.name) : '')"
              >
                <b-form-input id="comments" v-model="comments"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="!isDisabled()">
            <b-col>
              <div :class="isRefund ? 'alert-danger' : 'alert-info'" class="confirm-text">
                {{ choice.name }} de {{ sumPrice | currency }} sur l'ensemble des inscriptions
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput'
import SalesList from '@/components/Invoices/SalesList'
import { BackendMixin } from '@/mixins/backend'
import { makeChoice } from '@/types/base'
import { BackendApi } from '@/utils/http'
import { sum } from '@/utils/math'
import { getRandomId } from '@/utils/random'

const EXTRA = 1
const REFUND = 2

export default {
  name: 'youth-extra-sales-button',
  mixins: [BackendMixin],
  props: {
    entity: Object,
    inscriptions: Array,
  },
  components: {
    SalesList,
    DecimalInput,
  },
  data() {
    return {
      randomId: getRandomId(),
      choice: null,
      price: '',
      showPercentage: false,
      percentage: 0,
      comments: '',
      errorText: '',
      commentsTouched: false,
    }
  },
  computed: {
    sales() {
      return this.inscriptions.filter(elt => !!elt.sale).map(elt => elt.sale)
    },
    choices() {
      return [
        makeChoice({ id: EXTRA, name: 'Supplément', }),
        makeChoice({ id: REFUND, name: 'Réduction', })
      ]
    },
    isRefund() {
      return this.choice && this.choice.id === REFUND
    },
    extraPrice() {
      if (this.choice && !this.showPercentage && this.price) {
        let price = +this.price
        return (this.isRefund) ? -price : price
      }
      return 0
    },
    extraPercentage() {
      if (this.choice && this.showPercentage && this.percentage) {
        let percentage = +this.percentage
        return (this.isRefund) ? -percentage : percentage
      }
      return 0
    },
    sumPrice() {
      if (this.showPercentage) {
        return sum(
          this.inscriptions.map(elt => elt.sale.price * Math.abs(this.extraPercentage) / 100)
        )
      } else {
        return Math.abs(this.extraPrice) * this.inscriptions.length
      }
    },
    percentageValue() {
      return '' + this.percentage + '%'
    },
  },
  watch: {
    inscription: function(newValue, oldValue) {},
    entity: function(newValue, oldValue) {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      if (this.inscriptions.length) {
        this.$bvModal.show(this.getId())
      }
    },
    getId() {
      return 'bv-modal-youth-extra-sale-button' + this.randomId
    },
    isDisabled() {
      if (this.choice === null) {
        return true
      }
      if (this.showPercentage) {
        return ((isNaN(+this.percentage)) || (+this.percentage <= 0))
      } else {
        return ((isNaN(+this.price)) || (+this.price <= 0))
      }
    },
    async onSave() {
      this.errorText = ''
      try {
        let url = '/api/youth/add-inscriptions-extra-sales/'
        const backendApi = new BackendApi('post', url)
        let data = {
          inscriptions: this.inscriptions.map(elt => elt.id),
          price: this.extraPrice,
          percentage: this.extraPercentage,
          is_percentage: this.showPercentage,
          comments: this.comments,
        }
        await backendApi.callApi(data)
        this.$bvModal.hide(this.getId())
        if (this.isRefund) {
          await this.addSuccess('La réduction a été ajoutée')
        } else {
          await this.addSuccess('Le supplément a été ajouté')
        }
        this.$emit('done')
      } catch (err) {
        this.errorText = this.getErrorText(err)
        await this.addError(this.getErrorText(err))
      }
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
  .confirm-text {
    padding: 3px;
  }
</style>
