<template>
  <div>
    <div class="field-line" v-for="individual in displayedIndividuals" :key="individual.id">
      <div v-if="!!discounts[individual.id]">
        <b-row >
          <b-col class="inline-checkboxes">
            <b-checkbox
              :checked="discountsChecked[individual.id] && !extraChecked[individual.id]"
              :value="true"
              :unchecked-value="false"
              @change="setDiscountChecked(individual.id, $event)"
              :id="'discount-' + individual.id"
            >
              <b>Réduction</b>
            </b-checkbox>
            <b-checkbox
              :checked="discountsChecked[individual.id] && extraChecked[individual.id]"
              :value="true"
              :unchecked-value="false"
              :id="'extra-' + individual.id"
              @change="setExtraChecked(individual.id, $event)"
            >
              <b>Supplément</b>
            </b-checkbox>
             pour {{ individual.firstName }}
            <div class="small2" v-if="discountsChecked[individual.id] && !extraChecked[individual.id]">
              La réduction sera appliquée sur chaque séance payante cochée
            </div>
            <div class="small2" v-if="discountsChecked[individual.id] && extraChecked[individual.id]">
              Le supplément sera appliquée sur chaque séance payante cochée
            </div>
          </b-col>
        </b-row>
        <b-row v-if="discountsChecked[individual.id]">
          <b-col cols="3">
            <b-checkbox
              v-model="discounts[individual.id].showPercentage"
              :value="true"
              class="ut-show-percentage"
              :unchecked-value="false"
              @change="setDiscountShowPercentage(individual.id, $event)"
            >
              En pourcentage
            </b-checkbox>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label-cols="2"
              :id="'addDiscountAmountGroup' + individual.id"
              label="€"
              :label-for="'addDiscountAmount' + individual.id"
              v-if="!discounts[individual.id].showPercentage"
            >
              <decimal-input
                :id="'addDiscountAmount' + individual.id"
                v-model="discounts[individual.id].amount"
                :disabled="!discountsChecked[individual.id]"
                @change="setDiscountAmount(individual.id, $event)"
              >
              </decimal-input>
            </b-form-group>
            <b-form-group
              :id="'addDiscountPercentageGroup' + individual.id"
              label-cols="2"
              label="%"
              :label-for="'addDiscountPercentage' + individual.id"
              v-if="discounts[individual.id].showPercentage"
            >
              <b-form-input
                :id="'addDiscountPercentage' + individual.id"
                v-model="discounts[individual.id].percentage"
                type="number"
                :disabled="!discountsChecked[individual.id]"
                min="0"
                step="1"
                max="100"
                class="ut-percentage"
                @change="setDiscountPercentage(individual.id, $event)"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :id="'addDiscountCommentsGroup' + individual.id"
              :label-for="'addDiscountComments' + individual.id"
            >
              <b-form-input
                placeholder="Commentaires"
                :id="'addDiscountComments' + individual.id"
                v-model="discounts[individual.id].comments"
                :disabled="!discountsChecked[individual.id]"
                class="ut-comments"
                @change="setDiscountComments(individual.id, $event)"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput'
import { BackendMixin } from '@/mixins/backend'
import { makeChild } from '@/types/families'
import { makeDiscount } from '@/types/payments'
import { BackendApi } from '@/utils/http'

export default {
  name: 'AddYouthDiscount',
  props: {
    entity: Object,
    individuals: Array,
    onlyIndividuals: Array,
  },
  components: { DecimalInput, },
  mixins: [BackendMixin],
  data() {
    return {
      shown: false,
      discountsChecked: {},
      extraChecked: {},
      discounts: {},
    }
  },
  computed: {
    displayedIndividuals() {
      return (this.onlyIndividuals.length > 0) ? this.onlyIndividuals : this.individuals
    },
  },
  watch: {
    discounts: {
      handler: function() {
        this.discountsChanged()
      },
      deep: true,
    },
    discountsChecked: {
      handler: function() {
        this.discountsChanged()
      },
      deep: true,
    },
    extraChecked: {
      handler: function() {
        this.discountsChanged()
      },
      deep: true,
    },
    individuals: function() {},
    onlyIndividuals: function() {},
  },
  methods: {
    ...mapActions(['addError']),
    setDiscountChecked(individualId, value) {
      this.discountsChecked[individualId] = value
      this.extraChecked[individualId] = false
      this.discountsChecked = { ...this.discountsChecked, }
      this.extraChecked = { ...this.extraChecked, }
    },
    setExtraChecked(individualId, value) {
      this.discountsChecked[individualId] = value
      this.extraChecked[individualId] = value
      this.discountsChecked = { ...this.discountsChecked, }
      this.extraChecked = { ...this.extraChecked, }
    },
    setDiscountShowPercentage(individualId, value) {
      this.discounts[individualId].showPercentage = value
      this.discounts = { ...this.discounts, }
    },
    setDiscountPercentage(individualId, value) {
      this.discounts[individualId].percentage = value
      this.discounts = { ...this.discounts, }
    },
    setDiscountAmount(individualId, value) {
      this.discounts[individualId].amount = value
      this.discounts = { ...this.discounts, }
    },
    setDiscountComments(individualId, value) {
      this.discounts[individualId].comments = value
      this.discounts = { ...this.discounts, }
    },
    isZero(individualId) {
      let percentage = +this.discounts[individualId].percentage
      let amount = +this.discounts[individualId].amount
      return (this.discounts[individualId].showPercentage) ? (percentage === 0) : (amount === 0)
    },
    discountsChanged() {
      let discounts = {}
      for (let individual of this.individuals) {
        let individualId = individual.id
        if (this.discountsChecked[individualId] && !this.isZero(individualId)) {
          const discount = { ...this.discounts[individualId], }
          if (this.extraChecked[individualId]) {
            discount.percentage = -discount.percentage
            discount.amount = -discount.amount
          }
          discounts[individual.id] = discount
        }
      }
      this.$emit('discounts-changed', discounts)
    },
    async loadChildrenDiscounts() {
      let backendApi = null
      let url = '/api/family-children/' + this.entity.id + '/'
      backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.discounts = {}
        for (const individual of this.individuals) {
          this.discounts[individual.id] = makeDiscount()
        }
        let children = resp.data.map(elt => makeChild(elt))
        this.childrenData = children.reduce(
          (childrenData, child) => {
            let childDiscount = child.discountPercentage || 0
            this.discountsChecked[child.individualId] = !!childDiscount
            let discount = makeDiscount()
            if (childDiscount) {
              discount.showPercentage = true
              discount.percentage = childDiscount
            }
            this.discounts[child.individualId] = discount
            childrenData[child.individualId] = child
            return childrenData
          },
          {}
        )
        this.discounts = { ...this.discounts, }
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
  },
  mounted() {
    this.loadChildrenDiscounts()
  },
}
</script>
<style scoped lang="less">
.inline-checkboxes .custom-control {
  display: inline-block;
  margin-right: 5px;
}
</style>
