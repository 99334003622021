<template>
  <div>
    <check-box-select
      id=""
      class="child-selector"
      inline
      hide-all
      :initial-value="individuals"
      :choices="individuals"
      :name-callback="individualName"
      @init="onChanged($event)"
      @changed="onChanged($event)"
    ></check-box-select>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'

export default {
  name: 'EntityChildrenSelector',
  components: { CheckBoxSelect, },
  props: {
    individuals: Array,
  },
  mixins: [],
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
    individuals: function() {},
  },
  methods: {
    individualName(elt) {
      return elt.firstName
    },
    onChanged(event) {
      this.$emit('changed', event)
    },
  },
  mounted() {
  },
}
</script>
<style scoped lang="less">
</style>
