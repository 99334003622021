import { render, staticRenderFns } from "./EntityPublications.vue?vue&type=template&id=12af8601&scoped=true&"
import script from "./EntityPublications.vue?vue&type=script&lang=js&"
export * from "./EntityPublications.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12af8601",
  null
  
)

export default component.exports